const createSubscriptionPlanObj = (formValues) => {
  const values = { ...formValues };
  const subscriptionObj = {
    price: {},
  };

  Object.keys(values).forEach((item) => {
    if (item.includes("USD") || item.includes("RUB") || item.includes("EUR") || item.includes("UAH")) {
      subscriptionObj.price[`${item}`] = values[item];
    } else {
      subscriptionObj[`${item}`] = values[item];
    }
  });

  return subscriptionObj;
};

export default createSubscriptionPlanObj;
