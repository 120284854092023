import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";

import organizations from "./organizations";
import therapists from "./therapists";
import patients from "./patients";
import lists from "./lists";
import libraries from "./libraries";
import loader from "./loader";
import plans from "./plans";
import subscriptions from "./subscriptions";

const appReducer = combineReducers({
   form: formReducer,
   organizations: organizations,
   therapists: therapists,
   patients: patients,
   lists: lists,
   libraries: libraries,
   loader: loader,
   plans: plans,
   subscriptions: subscriptions,
});

export default appReducer;
