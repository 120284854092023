import React, { useEffect } from 'react'
import { connect } from 'react-redux';

import PatientDetailsForm from '../../components/PatientDetailsForm';

import { getPatientByIdService } from '../../services/patients/patients';
import {
    clearCurrentPatient,
    setCurrentEditingPatient
} from '../../actions/patients';

import Loader from '../../components/Loader';


const PatientDetails = ({
    match: { params },
    clearCurrentPatient,
    setCurrentEditingPatient,
    currentEditingPatient
}) => {

    useEffect(() => {
        clearCurrentPatient()
        getPatientByIdService(params.patientId)
            .then((data)=> setCurrentEditingPatient(data))
            .catch((err)=> console.log(err))
    }, []);

    return (
        <div className="w-100 content-container">
        {
            currentEditingPatient ? (
                <>
                    <div className="d-flex justify-content-between align-items-center">
                        <h1 className="mb-5">{currentEditingPatient.name.first} {currentEditingPatient.name.last}</h1>
                        <button
                            type="submit"
                            className="btn btn-primary"
                            form="patient-details-form"
                        >
                            <span className="btn-label">
                                <i className="fas fa-save mr-2"></i>
                            </span>
                            Save changes
                        </button>
                    </div>
                    <PatientDetailsForm patientId={params.patientId}/>
                </>
           ) : <Loader />
        } 
    </div>
    );
};

const mapStateToProps = ({ patients: { currentEditingPatient } }) => ({
    currentEditingPatient
});

const mapDispatchToProps = {
    clearCurrentPatient,
    setCurrentEditingPatient
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PatientDetails);
