import { connect } from "react-redux";

import PatientAssessmentFormTable from "./PatientAssessmentFormTable";

import { deletePatientAssessmentFormItem } from '../../actions/patients';

const mapDispatchToProps = {
    deletePatientAssessmentFormItem
};

export default connect(null, mapDispatchToProps)(PatientAssessmentFormTable);
