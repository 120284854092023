const libraryAdapter = (libraryObj) => {
    
    const libraryFormAdapter = { ...libraryObj };

    if(libraryFormAdapter.bodyRegion) {
        libraryFormAdapter.bodyRegion = libraryFormAdapter.bodyRegion.map(item =>  item.id || item)
    }
    if(libraryFormAdapter.categories) {
        libraryFormAdapter.categories = libraryFormAdapter.categories.map(item => item.id || item)
    }
   
    if(libraryFormAdapter.equipment && typeof libraryFormAdapter.equipment == 'object')  {
        libraryFormAdapter.equipment = libraryFormAdapter.equipment.map(item => item.id || item)
    }

    if(libraryFormAdapter.needPHHelp == 'null') {
        delete libraryFormAdapter.needPHHelp;
    }

    return libraryFormAdapter
}

export default libraryAdapter;