const initState = {
    librariesList: null,
    libraryAttachmentsList: [],
    currentEditingLibrary: null
};

const getLibrariesList = (state, action) => {
    return {
        ...state,
        librariesList: action.payload
    };
};

const addLibraryAttachment = (state, action) => {

    action.payload.blob = URL.createObjectURL(action.payload);
    action.payload.file = action.payload;
    action.payload._id = Date.now();

    return {
        ...state,
        libraryAttachmentsList: [ ...state.libraryAttachmentsList, action.payload]
    };
};

const deleteLibraryAttachment = (state, action) => {

    return {
        ...state,
        libraryAttachmentsList: state.libraryAttachmentsList.filter(item => item._id !== action.payload)
    };
};

const deleteLibrary = (state, action) => {

    return {
        ...state,
        librariesList: state.librariesList.filter(item => item._id !== action.payload)
    };
};

const libraryByIdLoaded = (state, action) => {

    return {
        ...state,
        currentEditingLibrary: action.payload,
        libraryAttachmentsList: action.payload ? action.payload.attachments : []
    };
};


const libraries = (state = initState, action) => {
    switch (action.type) {
        case "LIBRARIES_WAS_LOADED":
            return getLibrariesList(state, action);
        case "ADD_LIBRARY_ATTACHMENT":
            return addLibraryAttachment(state, action);
        case "DELETE_LIBRARY_ATTACHMENT":
            return deleteLibraryAttachment(state, action);
        case "DELETE_LIBRARY":
            return deleteLibrary(state, action);
        case "LIBRARY_BY_ID_LOADED":
            return libraryByIdLoaded(state, action);
        default:
            return state;
    }
};

export default libraries;