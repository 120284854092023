const initState = {
   subscriptionsList: null,
   currentEditingSubscription: null,
};

const subscriptionsListLoaded = (state, action) => {
   return {
      ...state,
      subscriptionsList: action.payload,
   };
};

const deleteSubscription = (state, action) => {
   return {
      ...state,
      subscriptionsList: state.subscriptionsList.filter((item) => item._id !== action.payload),
   };
};

const setCurrentEditingSubscription = (state, action) => {
   return {
      ...state,
      currentEditingSubscription: action.payload,
   };
};

const subscriptions = (state = initState, action) => {
   switch (action.type) {
      case "SUBSCRIPTIONS_WAS_LOADED":
         return subscriptionsListLoaded(state, action);
      case "DELETE_SUBSCRIPTION":
         return deleteSubscription(state, action);
      case "SET_CURRENT_EDITING_SUBSCRIPTION":
         return setCurrentEditingSubscription(state, action);
      default:
         return state;
   }
};

export default subscriptions;
