import config from "../config";

const saveTokenInLocalStorage = async (tokenData) => {
   if (!tokenData) {
      localStorage.clear();
      localStorage.setItem("isAuth", false);

      return false;
   } else {
      localStorage.setItem("isAuth", true);
      localStorage.setItem("auth", JSON.stringify(tokenData.token));
      localStorage.setItem("refreshToken", JSON.stringify(tokenData.refreshToken));

      return true;
   }
};

const refreshToken = async () => {
   const apiUrl = `${config._BaseUrlWithoutAdmin}/auth/update_token`;
   const token = {
      refresh_token: JSON.parse(localStorage.getItem("refreshToken")),
   };
   try {
      const response = await fetch(apiUrl, {
         method: "POST",
         headers: {
            "Content-Type": "application/json",
         },
         body: JSON.stringify(token),
      });

      let parsedResponse = await response.json();

      if (response.status >= 400) {
         localStorage.clear();
         localStorage.setItem("isAuth", false);
         window.location.replace("/login");
      } else {
         saveTokenInLocalStorage(parsedResponse.auth);
      }
   } catch (err) {
      console.log(err);
   }
};

export { saveTokenInLocalStorage, refreshToken };
