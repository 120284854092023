const initState = {
   plansList: null,
   currentEditingPlan: null,
};

const plansListLoaded = (state, action) => {
   return {
      ...state,
      plansList: action.payload,
   };
};

const deletePlan = (state, action) => {
   return {
      ...state,
      plansList: state.plansList.filter((item) => item._id !== action.payload),
   };
};

const setCurrentEditingPlan = (state, action) => {
   return {
      ...state,
      currentEditingPlan: action.payload,
   };
};

const plans = (state = initState, action) => {
   switch (action.type) {
      case "PLANS_WAS_LOADED":
         return plansListLoaded(state, action);
      case "DELETE_PLAN":
         return deletePlan(state, action);
      case "SET_CURRENT_EDITING_PLAN":
         return setCurrentEditingPlan(state, action);
      default:
         return state;
   }
};

export default plans;
