import config from '../config';


const getBlobFiles = async (fileUrl) => {
  try {
    const response = await fetch(`${fileUrl}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("auth"))}`,
      },
    })
      .then((response) => {
        return response.blob().then((blobResponse) => {
          return blobResponse;
        });
      })
      .catch((error) => {
        console.log(error);
      });
    return response;
  } catch (error) {
    console.log(error);
  }
};

const getPdfFile = async (fileUrl) => {
  try {
    const response = await fetch(`${config._Domain}/${fileUrl}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("auth"))}`,
      },
    })
      .then((response) => {
        return response.blob().then((blobResponse) => {
          return blobResponse;
        });
      })
      .catch((error) => {
        console.log(error);
      });
    return response;
  } catch (error) {
    console.log(error);
  }
};

export {
  getBlobFiles,
  getPdfFile
};
