import React, { useEffect } from "react";
import { connect } from "react-redux";

import TherapistsTable from "../../components/TherapistsTable";
import Loader from "../../components/Loader";

import { getTherapistsService } from "../../services/therapists/therapists";
import { getTherapistsList, deleteTherapist } from "../../actions/therapists";

const Therapists = ({ history, getTherapistsList, therapistsList, deleteTherapist }) => {
  useEffect(() => {
    getTherapistsService().then((data) => getTherapistsList(data.list));
  }, []);

  return (
    <div className="w-100 content-container">
      {therapistsList ? (
        <>
          <div className="d-flex align-items-center justify-content-between mb-3">
            <h1>Therapists</h1>
          </div>
          <TherapistsTable history={history} therapistsList={therapistsList} deleteTherapist={deleteTherapist} />
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
};

const mapStateToProps = ({ therapists: { therapistsList } }) => ({
  therapistsList,
});

const mapDispatchToProps = {
  getTherapistsList,
  deleteTherapist,
};

export default connect(mapStateToProps, mapDispatchToProps)(Therapists);
