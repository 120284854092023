import Subscriptions from "./Subscriptions";
import { connect } from "react-redux";

import { getSubscriptionsList } from "../../actions/subscriptions";

const mapStateToProps = ({ subscriptions: { subscriptionsList } }) => ({
    subscriptionsList,
});

const mapDispatchToProps = {
    getSubscriptionsList
};

export default connect(mapStateToProps, mapDispatchToProps)(Subscriptions);
