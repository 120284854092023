const PLAN_DURATION = [
  { title: "7 days", duration: 7 },
  { title: "14 days", duration: 14 },
  { title: "1 month", duration: 31 },
  { title: "3 months", duration: 91 },
  { title: "6 months", duration: 182 },
  { title: "1 year", duration: 364 },
];

export default PLAN_DURATION;
