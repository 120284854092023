import React, { useEffect } from "react";
import { Field, reduxForm } from "redux-form";

import Loader from "../../components/Loader";
import PlansTable from "../../components/PlansTable";

import { getPlansService } from "../../services/plans/plans";

import checkUserEmailInBlackList from "../../helpers/checkUserEmailInBlackList";

const Plans = ({ history, plansList, getPlansList }) => {
  useEffect(() => {
    if (!checkUserEmailInBlackList()) {
      getPlansService().then((data) => getPlansList(data.items));
    }
  }, []);

  if (checkUserEmailInBlackList()) {
    return (
      <div className="w-100 content-container">
        <div className="alert alert-danger text-center" role="alert">
          You don`t have access to view this list!
        </div>
      </div>
    );
  }

  return (
    <div className="w-100 content-container">
      {plansList ? (
        <>
          <div className="d-flex align-items-center justify-content-between mb-3">
            <h1>Plans</h1>

            <button className="ml-5 btn btn-primary" onClick={() => history.push("plans/new")}>
              <i className="fas fa-folder-plus mr-2"></i>
              New plan item
            </button>
          </div>
          <PlansTable history={history} plansList={plansList} />
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default reduxForm({ form: "SearchPlansField" })(Plans);
