import React, { useEffect } from "react";
import { connect } from "react-redux";

import OrganizationsDetailsForm from "../../components/OrganizationDetailsForm";
import Loader from "../../components/Loader";

import { getOrganizationById } from "../../services/organizations/organizations";
import {
   getCountriesListService,
   getCurrenciesListService,
   getUnitSystemsListService,
} from "../../services/lists/lists";
import {
   setCurrentEditingOrganization,
   clearCurrentOrganization,
   updateCurrentOrganization,
} from "../../actions/organizations";
import { getCountriesList, getCurrenciesList, getUnitSystems } from "../../actions/lists";

const OrganizationsDetails = ({
   match: { params },
   currentEditingOrganization,
   setCurrentEditingOrganization,
   clearCurrentOrganization,
   getCountriesList,
   getCurrenciesList,
   getUnitSystems,
   updateCurrentOrganization,
}) => {
   useEffect(() => {
      clearCurrentOrganization();
      getCountriesListService().then((data) => getCountriesList(data));
      getCurrenciesListService().then((data) => getCurrenciesList(data));
      getUnitSystemsListService().then((data) => getUnitSystems(data));
      getOrganizationById(params.organizationId).then((data) => setCurrentEditingOrganization(data));
   }, []);

   return (
      <div className="w-100 content-container">
         {currentEditingOrganization ? (
            <>
               <div className="d-flex justify-content-between align-items-center">
                  <h1 className="mb-5">
                     {currentEditingOrganization.title ? currentEditingOrganization.title : "Unnamed organization"}
                  </h1>
                  <button type="submit" className="btn btn-primary" form="organization-details-form">
                     <span className="btn-label">
                        <i className="fas fa-save mr-2"></i>
                     </span>
                     Save changes
                  </button>
               </div>
               <OrganizationsDetailsForm
                  organizationId={params.organizationId}
                  updateCurrentOrganization={updateCurrentOrganization}
               />
            </>
         ) : (
            <Loader />
         )}
      </div>
   );
};

const mapStateToProps = ({ organizations: { currentEditingOrganization } }) => ({
   currentEditingOrganization,
});

const mapDispatchToProps = {
   setCurrentEditingOrganization,
   clearCurrentOrganization,
   updateCurrentOrganization,
   getCountriesList,
   getCurrenciesList,
   getUnitSystems,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationsDetails);
