import React from 'react'

import './styles.css'
import { NavLink } from 'react-router-dom';

const Menu = () => {

    return (
        <div className="bg-light border-right menu-wrapper">
            <div className="list-group list-group-flush">
                <NavLink to="/organizations" activeClassName="bg-primary text-light">
                    <div className="border p-3">
                        <i className="fas fa-sitemap mr-4"></i>
                        Organizations
                    </div>
                </NavLink>
                <NavLink to="/therapists" activeClassName="bg-primary text-light">
                    <div className="border p-3">
                        <i className="fas fa-user-md mr-4"></i>
                        Therapists
                    </div>
                </NavLink>
                <NavLink to="/patients" activeClassName="bg-primary text-light">
                    <div className="border p-3">
                        <i className="fas fa-user-injured mr-4"></i>
                        Patients
                    </div>
                </NavLink>
                <NavLink to="/libraries" activeClassName="bg-primary text-light">
                    <div className="border p-3">
                        <i className="fas fa-book mr-4"></i>
                        Libraries
                    </div>
                </NavLink>
                <NavLink to="/plans" activeClassName="bg-primary text-light">
                    <div className="border p-3">
                    <i className="fas fa-file-invoice-dollar mr-4"></i>
                        Plans
                    </div>
                </NavLink>
                <NavLink to="/subscriptions" activeClassName="bg-primary text-light">
                    <div className="border p-3">
                    <i className="fas fa-calendar-check mr-4"></i>
                        Subscriptions
                    </div>
                </NavLink>
            </div>
        </div>
    );
};

export default Menu;