const getCountriesList = (data) => {
  return {
    type: "COUNTRIES_LIST_LOADED",
    payload: data,
  };
};

const getLanguagesList = (data) => {
  return {
    type: "LANGUAGES_LIST_LOADED",
    payload: data,
  };
};

const getCurrenciesList = (data) => {
  return {
    type: "CURRENCIES_LIST_LOADED",
    payload: data,
  };
};

const getUnitSystems = (data) => {
  return {
    type: "UNIT_SYSTEMS_LIST_LOADED",
    payload: data,
  };
};

const libraryParamsListLoaded = (data) => {
  return {
    type: "LIBRARY_PARAMS_LIST_LOADED",
    payload: data,
  };
};

const diseasesListLoaded = (data) => {
  return {
    type: "DISEASES_LIST_LOADED",
    payload: data,
  };
};

export {
  getCountriesList,
  getLanguagesList,
  getCurrenciesList,
  getUnitSystems,
  libraryParamsListLoaded,
  diseasesListLoaded,
};
