const productionConfig = {
    _BaseUrl: "https://api.re-move.io/api/v1/admin",
    _Domain: "https://api.re-move.io",
    _BaseUrlWithoutAdmin: "https://api.re-move.io/api/v1"
}

const developmentConfig = {
    _BaseUrl: "http://188.40.167.45:3002/api/v1/admin",
    _Domain: "http://188.40.167.45:3002",
    _BaseUrlWithoutAdmin: "http://188.40.167.45:3002/api/v1"
}

const config = process.env.REACT_APP_NODE_ENVIRONMENT === "development" ? developmentConfig : productionConfig;

export default config;