import React, { useEffect } from "react";
import { connect } from "react-redux";

import Loader from "../../components/Loader";
import SubscriptionDetailsForm from "../../components/SubscriptionDetailsForm";

import { setCurrentEditingSubscription } from "../../actions/subscriptions";

import { getSubscriptionByIDService } from "../../services/subscriptions/subscriptions";

const SubscriptionDetails = ({
   history,
   match: { params },
   currentEditingSubscription,
   isVisibleLoader,
   setCurrentEditingSubscription,
}) => {
   useEffect(() => {
      setCurrentEditingSubscription(null);
      getSubscriptionByIDService(params.subscriptionID).then((data) => setCurrentEditingSubscription(data));
   }, []);

   return (
      <div className="w-100 content-container">
         {currentEditingSubscription ? (
            <>
               <div className="d-flex align-items-center justify-content-between mb-5">
                  <h1> Subscription №{currentEditingSubscription._id}</h1>
                  <button className="ml-5 btn btn-primary" form="subscription-form">
                     {isVisibleLoader ? (
                        <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                     ) : (
                        <i className="fas fa-save mr-2"></i>
                     )}
                     Save changes
                  </button>
               </div>
               <SubscriptionDetailsForm subscriptionID={params.subscriptionID} history={history} />
            </>
         ) : (
            <Loader />
         )}
      </div>
   );
};

const mapStateToProps = ({ subscriptions: { currentEditingSubscription }, loader: { isVisibleLoader } }) => ({
   currentEditingSubscription,
   isVisibleLoader,
});

const mapDispatchToProps = {
   setCurrentEditingSubscription,
};

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionDetails);
