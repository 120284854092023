import { connect } from "react-redux";

import PatientNotesTable from "./PatientNotesTable";

import { deletePatientNoteItem } from '../../actions/patients';

const mapDispatchToProps = {
    deletePatientNoteItem
};

export default connect(null, mapDispatchToProps)(PatientNotesTable);
