import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";

import LoginPage from "./pages/Login";
import Menu from "./components/Menu";
import Header from "./components/Header";
import Organizations from "./pages/Organizations/Organizations";
import Therapists from "./pages/Therapists/Therapists";
import Patients from "./pages/Patients/Patients";
import OrganizationDetails from "./pages/Organizations/OrganizationDetails";
import TherapistDetails from "./pages/Therapists/TherapistsDetails";
import PatientDetails from "./pages/Patients/PatientDetails";
import Libraries from "./pages/Libraries/Libraries";
import NewLibrary from "./pages/Libraries/NewLibrary";
import LibraryDetails from "./pages/Libraries/LibraryDetails";
import Plans from "./pages/Plans";
import Subscriptions from "./pages/Subscriptions";
import ProtectedRoute from "./components/ProtectedRoute";
import PlanDetails from "./pages/Plans/PlanDetails";
import NewPlan from "./pages/Plans/NewPlan";
import SubscriptionDetails from "./pages/Subscriptions/SubscriptionDetails";
import PatientDiseases from "./pages/Patients/PatientDiseases";
import PatientNotes from "./pages/Patients/PatientNotes";
import PatientAttachments from "./pages/Patients/PatientAttachments";
import PatientAssessmentForms from "./pages/Patients/PatientAssessmentForms";
import PatientAppointments from "./pages/Patients/PatientAppointments";
import PatientHomeworks from "./pages/Patients/PatientHomeworks";

import { getLanguagesList, diseasesListLoaded } from "./actions/lists";

import { getLanguagesListService, getDiseasesListService } from "./services/lists/lists";

import "./assets/styles.css";

const App = ({ getLanguagesList, diseasesListLoaded }) => {
  const [availableToken, setAvailableToken] = useState(false);
  useEffect(() => {
    let token = JSON.parse(localStorage.getItem("auth"));
    if (token) {
      getLanguagesListService().then((data) => getLanguagesList(data));
      getDiseasesListService().then((data) => diseasesListLoaded(data.list));
    }
  }, [availableToken]);

  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Redirect to="/organizations" />
        </Route>
        <Route
          exact
          path="/login"
          component={(props) => <LoginPage {...props} setAvailableToken={setAvailableToken} />}
        />
        <React.Fragment>
          <Header />
          <div className="d-flex w-100">
            <Menu />
            <ProtectedRoute exact path="/therapists" component={(props) => <Therapists {...props} />} />
            <ProtectedRoute
              exact
              path="/therapists/:therapistId"
              component={(props) => <TherapistDetails {...props} />}
            />
            <ProtectedRoute exact path="/organizations" component={(props) => <Organizations {...props} />} />
            <ProtectedRoute
              exact
              path="/organizations/:organizationId"
              component={(props) => <OrganizationDetails {...props} />}
            />
            <ProtectedRoute exact path="/patients" component={(props) => <Patients {...props} />} />
            <ProtectedRoute exact path="/patients/:patientId" component={(props) => <PatientDetails {...props} />} />
            <ProtectedRoute
              exact
              path="/patients/:patientId/diseases"
              component={(props) => <PatientDiseases {...props} />}
            />
            <ProtectedRoute
              exact
              path="/patients/:patientId/notes"
              component={(props) => <PatientNotes {...props} />}
            />

            <ProtectedRoute
              exact
              path="/patients/:patientId/attachments"
              component={(props) => <PatientAttachments {...props} />}
            />
            <ProtectedRoute
              exact
              path="/patients/:patientId/assessment-forms"
              component={(props) => <PatientAssessmentForms {...props} />}
            />
            <ProtectedRoute
              exact
              path="/patients/:patientId/appointments"
              component={(props) => <PatientAppointments {...props} />}
            />
            <ProtectedRoute
              exact
              path="/patients/:patientId/homeworks"
              component={(props) => <PatientHomeworks {...props} />}
            />
            <ProtectedRoute exact path="/libraries" component={(props) => <Libraries {...props} />} />
            <ProtectedRoute exact path="/libraries/new" component={(props) => <NewLibrary {...props} />} />
            <ProtectedRoute
              exact
              path="/libraries/id/:libraryId"
              component={(props) => <LibraryDetails {...props} />}
            />
            <ProtectedRoute exact path="/plans" component={(props) => <Plans {...props} />} />
            <ProtectedRoute exact path="/plans/new" component={(props) => <NewPlan {...props} />} />
            <ProtectedRoute exact path="/subscriptions" component={(props) => <Subscriptions {...props} />} />
            <ProtectedRoute
              exact
              path="/subscriptions/id/:subscriptionID"
              component={(props) => <SubscriptionDetails {...props} />}
            />
            <ProtectedRoute exact path="/plans/id/:plansID" component={(props) => <PlanDetails {...props} />} />
          </div>
        </React.Fragment>
      </Switch>
    </Router>
  );
};

const mapDispatchToProps = {
  getLanguagesList,
  diseasesListLoaded,
};

export default connect(null, mapDispatchToProps)(App);
