const initState = {
    organizationsList: null,
    currentEditingOrganization: null
};

const organizationsListLoaded = (state, action) => {
    return {
        ...state,
        organizationsList: action.payload
    };
};

const deleteOrganization = (state, action) => {

    return {
        ...state,
        organizationsList: state.organizationsList.filter((item) => item._id !== action.payload)
    };
}

const setCurrentEditingOrganization = (state, action) => {

    return {
        ...state,
        currentEditingOrganization: action.payload
    };
}

const clearCurrentOrganization = (state) => {

    return {
        ...state,
        currentEditingOrganization: null
    };
}

const updateCurrentOrganization = (state, action) => {

    return {
        ...state,
        currentEditingOrganization: action.payload
    };
}


const organizations = (state = initState, action) => {
    switch (action.type) {
        case "ORGANIZATIONS_WAS_LOADED":
            return organizationsListLoaded(state, action);
        case "DELETE_ORGANIZATION":
            return deleteOrganization(state, action);
        case "SET_CURRENT_EDITING_ORGANIZATION": 
            return setCurrentEditingOrganization(state, action)
        case "CLEAR_CURRENT_ORGANIZATION": 
            return clearCurrentOrganization(state);
        case "UPDATE_CURRENT_ORGANIZATION": 
            return updateCurrentOrganization(state, action);
        default:
            return state;
    }
};

export default organizations;