import USERS_EMAIL_BLACK_LIST from "../constants/usersEmailBlackList";

const checkUserEmailInBlackList = () => {
    if (USERS_EMAIL_BLACK_LIST.find((item) => item === localStorage.getItem("currentLoginedUserEmail"))) {
        return true;
    } else {
        return false;
    }
};

export default checkUserEmailInBlackList;
