import React from "react";
import dayjs from "dayjs";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search, CSVExport } from "react-bootstrap-table2-toolkit";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

const { SearchBar } = Search;
const { ExportCSVButton } = CSVExport;

const SubscriptionsTable = ({ history, subscriptionsList }) => {
  const columns = [
    {
      dataField: "organizationId",
      text: "Organization ID",
      sort: true,
    },
    {
      dataField: "expirationDate",
      text: "Expiration date",
      sort: true,
      formatter: (cell, row) => {
        return <>{dayjs(row.expirationDate).format("YYYY-MM-DD")}</>;
      },
    },
    {
      dataField: "startDate",
      text: "Start date",
      sort: true,
      formatter: (cell, row) => {
        return <>{dayjs(row.startDate).format("YYYY-MM-DD")}</>;
      },
    },
    {
      dataField: "isActive",
      text: "Activity status",
      sort: true,
    },
    {
      dataField: "action",
      text: "Actions",
      sort: false,
      formatter: (cell, row) => {
        return (
          <div className="d-flex justify-content-around">
            <button
              className="btn btn-warning btn-rounded w-100 mr-2"
              onClick={() => history.push(`/subscriptions/id/${row._id}`)}
            >
              <i className="far fa-edit mr-2"></i>Edit
            </button>
          </div>
        );
      },
    },
  ];

  const defaultSorted = [
    {
      dataField: "organizationId",
      order: "desc",
    },
  ];

  return (
    <ToolkitProvider keyField="_id" bootstrap4 data={subscriptionsList} columns={columns} search>
      {(props) => (
        <div>
          <div className="d-flex align-items-center">
            <ExportCSVButton {...props.csvProps} className="btn btn-success btn-rounded mb-2 mr-4">
              <i className="fas fa-file-download mr-2"></i>
              Export CSV
            </ExportCSVButton>
            <p className="mb-2 mr-2">Search in table:</p>
            <SearchBar {...props.searchProps} />
          </div>
          <BootstrapTable
            bootstrap4
            defaultSorted={defaultSorted}
            pagination={paginationFactory()}
            noDataIndication="Nothing found. Try to change your request!"
            {...props.baseProps}
            wrapperClasses="table-responsive"
          />
        </div>
      )}
    </ToolkitProvider>
  );
};

export default SubscriptionsTable;
