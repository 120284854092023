const getTherapistsList = (data) => {
    return {
        type: 'THERAPISTS_WAS_LOADED',
        payload: data
    };
};

const getCurrentEditingTherapist = (data) => {
    return {
        type: 'SET_CURRENT_EDITING_THERAPIST',
        payload: data
    };
};

const clearCurrentEditingTherapist = () => {
    return {
        type: 'CLEAR_CURRENT_THERAPIST'
    };
};

const deleteTherapist = (therapistId) => {
    return {
        type: 'DELETE_THERAPIST',
        payload: therapistId
    };
};

export {
    getTherapistsList,
    getCurrentEditingTherapist,
    clearCurrentEditingTherapist,
    deleteTherapist
}