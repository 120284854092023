import React from 'react';
import Multiselect from 'react-widgets/lib/Multiselect';

import 'react-widgets/dist/css/react-widgets.css'

const renderMultiSelect = ({ input, data, valueField, textField, label, meta: { touched, error } }) => {
    return (
        <div className="form-group col-md-8">
            <label>{label}</label>
            <Multiselect {...input}
                value={input.value || []}
                data={data}
                valueField={valueField}
                textField={textField}
                onBlur={() => input.onBlur([...input.value])}
            />
            {touched && (error && <div className="alert alert-danger mb-0 mt-1">{error}</div>)}
        </div>
    )
}

export default renderMultiSelect;

