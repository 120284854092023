import React, { useEffect } from "react";
import { connect } from "react-redux";

import PatientDiseasesTable from "../../components/PatientDiseasesTable";

import { getPatientDiseasesService } from "../../services/patients/patients";
import { patientDiseasesLoaded } from "../../actions/patients";

import Loader from "../../components/Loader";

const PatientDiseases = ({ match: { params }, patientDiseasesLoaded, patientDiseasesList, diseasesList }) => {
  useEffect(() => {
    getPatientDiseasesService(params.patientId)
      .then((data) => patientDiseasesLoaded(data))
      .catch((err) => console.log(err));
  }, []);

  return (
    <div className="w-100 content-container">
      {patientDiseasesList && diseasesList ? (
        <>
          <div className="d-flex justify-content-between align-items-center">
            <h1 className="mb-5">Patient №{params.patientId} - Diseases</h1>
          </div>
          {patientDiseasesList.length > 0 ? (
            <PatientDiseasesTable
              patientDiseasesList={patientDiseasesList}
              diseasesList={diseasesList}
              patientID={params.patientId}
            />
          ) : (
            <div className="alert alert-danger text-center">
              <strong>Patient doesn`t have any associated diseases!</strong>
            </div>
          )}
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
};

const mapStateToProps = ({ patients: { patientDiseasesList }, lists: { diseasesList } }) => ({
  patientDiseasesList,
  diseasesList,
});

const mapDispatchToProps = {
  patientDiseasesLoaded,
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientDiseases);
