import React, { useEffect } from 'react'
import { connect } from 'react-redux';

import LibraryForm from '../../components/LibraryForm';
import Loader from '../../components/Loader';

import { libraryParamsListLoaded } from '../../actions/lists';
import { libraryByIdLoaded } from '../../actions/libraries';
import { setLoaderVisibilityStatus } from '../../actions/loader';
import { getLibraryParamsService } from '../../services/lists/lists';
import { getLibraryByIdService } from '../../services/libraries/libraries';


const LibraryDetails = ({ 
    history,
    match: { params },
    libraryParamsListLoaded,
    libraryParams,
    currentEditingLibrary,
    libraryByIdLoaded,
    setLoaderVisibilityStatus,
    isVisibleLoader
}) => {


    useEffect(() => {
        libraryByIdLoaded(null)
        getLibraryParamsService()
            .then((data) => libraryParamsListLoaded(data))
        getLibraryByIdService(params.libraryId)
            .then((data) => libraryByIdLoaded(data))
    }, []);

    return (
        <div className="w-100 content-container">
            {
                currentEditingLibrary && libraryParams ? (
                    <>
                        <div className="d-flex align-items-center justify-content-between mb-5">
                            <h1>{currentEditingLibrary.name}</h1>
                            <button
                                className="ml-5 btn btn-primary"
                                form="library-form"
                                >
                                {
                                    isVisibleLoader ? <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span> : <i className="fas fa-save mr-2"></i>
                                }
                            Save changes
                    </button>

                        </div>
                        <LibraryForm 
                            libraryParametrs={libraryParams} 
                            libraryId={params.libraryId}
                            history={history}
                            setLoaderVisibilityStatus={setLoaderVisibilityStatus}
                        />
                    </>
                ) : <Loader />
            }
        </div>
    );
};

const mapStateToProps = ({ lists: { libraryParams }, libraries: { currentEditingLibrary }, loader: { isVisibleLoader } }) => ({
    libraryParams,
    currentEditingLibrary,
    isVisibleLoader
});

const mapDispatchToProps = {
    libraryParamsListLoaded,
    libraryByIdLoaded,
    setLoaderVisibilityStatus
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LibraryDetails);