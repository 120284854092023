import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";

import PatientsTable from "../../components/PatientsTable";
import Loader from "../../components/Loader";

import { getPatientsService } from "../../services/patients/patients";
import { getPatientsList, deletePatient } from "../../actions/patients";
import checkUserEmailInBlackList from "../../helpers/checkUserEmailInBlackList";

import "../styles.css";

const Patients = ({ history, getPatientsList, patientsList, deletePatient }) => {
    useEffect(() => {
        if (!checkUserEmailInBlackList()) {
            getPatientsService()
                .then((data) => getPatientsList(data.list))
                .catch(() => history.push("/login"));
        }
    }, []);

    if (checkUserEmailInBlackList()) {
        return (
            <div className="w-100 content-container">
                <div className="alert alert-danger text-center" role="alert">
                    You don`t have access to view this list!
                </div>
            </div>
        );
    }

    return (
        <div className="w-100 content-container">
            {patientsList ? (
                <>
                    <div className="d-flex align-items-center justify-content-between mb-3">
                        <h1>Patients</h1>
                    </div>
                    <PatientsTable history={history} patientsList={patientsList} deletePatient={deletePatient} />
                </>
            ) : (
                <Loader />
            )}
        </div>
    );
};

const mapStateToProps = ({ patients: { patientsList } }) => ({
    patientsList,
});

const mapDispatchToProps = {
    getPatientsList,
    deletePatient,
};

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({ form: "SearchPatientsField" })(Patients));
