import React from 'react'
import { Link } from 'react-router-dom';

import Logo from '../../assets/images/Logo.png'

import './styles.css'

const Header = () => {

    return (
        <nav className="navbar navbar-default bg-light border-bottom">
            <div className="container-fluid">
                <div className="navbar-header w-100 d-flex justify-content-between align-items-center">
                    <a className="navbar-brand" href="/organizations">
                        <img className="h-100 w-25" alt="Brand" src={Logo} />
                    </a>
                    <Link to="/login" onClick={()=> localStorage.clear()}>
                        <button
                            type="button"
                            className="btn btn-primary"
                        >
                            <span className="btn-label">
                                <i className="fas fa-sign-out-alt mr-2"></i>
                            </span>
                                Log Out
                            </button>
                    </Link>
                </div>
            </div>
        </nav>
    );
};

export default Header;