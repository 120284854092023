import { connect } from "react-redux";
import dayjs from "dayjs";

import SubscriptionDetailsForm from "./SubscriptionDetailsForm";

import { setLoaderVisibilityStatus } from "../../actions/loader";

const mapStateToProps = ({ subscriptions: { currentEditingSubscription } }) => ({
   currentEditingSubscription,
   initialValues: {
      USD: currentEditingSubscription ? currentEditingSubscription.price.USD : "",
      EUR: currentEditingSubscription ? currentEditingSubscription.price.EUR : "",
      RUB: currentEditingSubscription ? currentEditingSubscription.price.RUB : "",
      UAH: currentEditingSubscription ? currentEditingSubscription.price.UAH : "",
      countOfPatients: currentEditingSubscription ? currentEditingSubscription.countOfPatients : 1,
      countOfTherapists: currentEditingSubscription ? currentEditingSubscription.countOfTherapists : 1,
      expirationDate: currentEditingSubscription
         ? dayjs(currentEditingSubscription.expirationDate).format("YYYY-MM-DD")
         : dayjs().format("YYYY-MM-DD"),
   },
});

const mapDispatchToProps = {
   setLoaderVisibilityStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionDetailsForm);
