import { connect } from "react-redux";

import PlansTable from "./PlansTable";

import { deletePlan } from "../../actions/plans";

const mapDispatchToProps = {
    deletePlan
};

export default connect(null, mapDispatchToProps)(PlansTable);
