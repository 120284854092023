const initState = {
  patientsList: null,
  currentEditingPatient: null,
  patientDiseasesList: null,
  patientNotes: null,
  patientAttachments: null,
  patientAssessmentForms: null,
  patientAppointments: null,
  patientHomeworksList: null,
};

const patientsListLoaded = (state, action) => {
  return {
    ...state,
    patientsList: action.payload,
  };
};

const deletePatient = (state, action) => {
  return {
    ...state,
    patientsList: state.patientsList.filter((item) => item._id !== action.payload),
  };
};

const setCurrentEditingPatient = (state, action) => {
  return {
    ...state,
    currentEditingPatient: action.payload,
  };
};

const clearCurrentPatient = (state) => {
  return {
    ...state,
    currentEditingPatient: null,
  };
};

const patientDiseasesListLoaded = (state, action) => {
  return {
    ...state,
    patientDiseasesList: action.payload,
  };
};

const deletePatientDiseaseItem = (state, action) => {
  return {
    ...state,
    patientDiseasesList: state.patientDiseasesList.filter((disease) => disease._id !== action.payload),
  };
};

const patientNotesLoaded = (state, action) => {
  return {
    ...state,
    patientNotes: action.payload,
  };
};

const deletePatientNoteItem = (state, action) => {
  return {
    ...state,
    patientNotes: state.patientNotes.filter((note) => note._id !== action.payload),
  };
};

const patientAttachmentsLoaded = (state, action) => {
  return {
    ...state,
    patientAttachments: action.payload,
  };
};

const deletePatientAttachmentItem = (state, action) => {
  return {
    ...state,
    patientAttachments: state.patientAttachments.filter((attachment) => attachment._id !== action.payload),
  };
};

const patientAssessmentFormsLoaded = (state, action) => {
  return {
    ...state,
    patientAssessmentForms: action.payload,
  };
};

const deletePatientAssessmentFormItem = (state, action) => {
  return {
    ...state,
    patientAssessmentForms: state.patientAssessmentForms.filter(
      (assessmentForm) => assessmentForm._id !== action.payload
    ),
  };
};

const patientAppointmentsLoaded = (state, action) => {
  return {
    ...state,
    patientAppointments: action.payload,
  };
};

const deletePatientAppointmentItem = (state, action) => {
  return {
    ...state,
    patientAppointments: state.patientAppointments.filter((appointment) => appointment._id !== action.payload),
  };
};

const patientHomeworksListLoaded = (state, action) => {
  return {
    ...state,
    patientHomeworksList: action.payload,
  };
};

const deletePatientHomeworksItem = (state, action) => {
  return {
    ...state,
    patientHomeworksList: state.patientHomeworksList.filter((homework) => homework._id !== action.payload),
  };
};

const patients = (state = initState, action) => {
  switch (action.type) {
    case "PATIENTS_WAS_LOADED":
      return patientsListLoaded(state, action);
    case "DELETE_PATIENT":
      return deletePatient(state, action);
    case "SET_CURRENT_EDITING_PATIENT":
      return setCurrentEditingPatient(state, action);
    case "CLEAR_CURRENT_PATIENT":
      return clearCurrentPatient(state);
    case "PATIENT_DISEASES_LOADED":
      return patientDiseasesListLoaded(state, action);
    case "DELETE_PATIENT_DISEASE_ITEM":
      return deletePatientDiseaseItem(state, action);
    case "PATIENT_NOTES_LOADED":
      return patientNotesLoaded(state, action);
    case "DELETE_PATIENT_NOTE_ITEM":
      return deletePatientNoteItem(state, action);
    case "PATIENT_ATTACHMENTS_LOADED":
      return patientAttachmentsLoaded(state, action);
    case "DELETE_PATIENT_ATTACHMENT_ITEM":
      return deletePatientAttachmentItem(state, action);
    case "PATIENT_ASSESSMENT_FORMS_LOADED":
      return patientAssessmentFormsLoaded(state, action);
    case "DELETE_PATIENT_ASSESSMENT_FORM_ITEM":
      return deletePatientAssessmentFormItem(state, action);
    case "PATIENT_APPOINTMENTS_LOADED":
      return patientAppointmentsLoaded(state, action);
    case "DELETE_PATIENT_APPOINTMENT_ITEM":
      return deletePatientAppointmentItem(state, action);
    case "PATIENT_HOMEWORK_LIST_LOADED":
      return patientHomeworksListLoaded(state, action);
    case "DELETE_PATIENT_HOMEWORK_ITEM":
      return deletePatientHomeworksItem(state, action);
    default:
      return state;
  }
};

export default patients;
