import dayjs from "dayjs";

const validate = (values) => {
  const errors = {};
  if (!values.title_uk) {
    errors.title_uk = "Required field";
  }

  if (!values.title_en) {
    errors.title_en = "Required field";
  }

  if (!values.title_ru) {
    errors.title_ru = "Required field";
  }

  if (!values.UAH) {
    errors.UAH = "Required field";
  }

  if (!values.USD) {
    errors.USD = "Required field";
  }

  if (!values.EUR) {
    errors.EUR = "Required field";
  }

  if (!values.RUB) {
    errors.RUB = "Required field";
  }

  if (!values.description_uk) {
    errors.description_uk = "Required field";
  }

  if (!values.description_en) {
    errors.description_en = "Required field";
  }

  if (!values.description_ru) {
    errors.description_ru = "Required field";
  }

  if (!values.price) {
    errors.price = "Required field";
  }

  if (!values.countOfPatients) {
    errors.countOfPatients = "Required field";
  }

  if (!values.countOfTherapists) {
    errors.countOfTherapists = "Required field";
  }

  if (!values.expirationDate) {
    errors.expirationDate = "Required field";
  }

  if (dayjs(values.expirationDate).diff(dayjs().format("YYYY-MM-DD"), "day") < 0) {
    errors.expirationDate = "Incorrect date!";
  }

  return errors;
};

export default validate;
