import React from 'react';

import './style.css'

const Loader = () => {
    return (
        <div className="lds-hourglass"></div>
    )
}

export default Loader;