const getPlansList = (data) => {
   return {
      type: "PLANS_WAS_LOADED",
      payload: data,
   };
};

const deletePlan = (planID) => {
   return {
      type: "DELETE_PLAN",
      payload: planID,
   };
};

const setCurrentEditingPlan = (data) => {
   return {
      type: "SET_CURRENT_EDITING_PLAN",
      payload: data,
   };
};


export { getPlansList, deletePlan, setCurrentEditingPlan };
