import config from '../config';

const getOrganizationsService = async () => {


    try {
        const response = await fetch(`${config._BaseUrl}/organization/all`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem('auth'))}`
            }
        });

        if (response.status > 204) {
            throw new Error('GET organizations error')
        }

        return await response.json();
    } catch (error) {
        throw new Error('GET organizations error')
    }
}

const getOrganizationById = async (organizationId) => {

    try {
        const response = await fetch(`${config._BaseUrl}/organization/${organizationId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem('auth'))}`
            }
        });

        if (response.status > 204) {
            throw new Error('GET organization error')
        }

        return await response.json();
    } catch (error) {
        throw new Error('GET organization error')
    }
}

const deleteOrganizationService = async (organizationId) => {

    try {
        const response = await fetch(`${config._BaseUrl}/organization`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem('auth'))}`
            },
            body: JSON.stringify({ id: organizationId })
        });

        if (response.status > 204) {
            throw new Error('DELETE organizations error')
        }

        return await response.json();
    } catch (error) {
        throw new Error('DELETE organizations error')
    }
}

const updateOrganizationByIdService = async (organizationId, newOrganizationInfo) => {

    try {
        const response = await fetch(`${config._BaseUrl}/organization/${organizationId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem('auth'))}`
            },
            body: JSON.stringify(newOrganizationInfo)
        });

        if (response.status > 204) {
            throw new Error('Update organizations error')
        }

        return await response.json();
    } catch (error) {
        throw new Error('Update organizations error')
    }
}

export {
    getOrganizationsService,
    deleteOrganizationService,
    getOrganizationById,
    updateOrganizationByIdService
}