import React, { useEffect } from "react";
import { connect } from "react-redux";

import PatientAttachmentsTable from "../../components/PatientAttachmentsTable";

import { getPatientAttachmentsService } from "../../services/patients/patients";
import { patientAttachmentsLoaded } from "../../actions/patients";

import Loader from "../../components/Loader";

const PatientAttachments = ({ match: { params }, patientAttachmentsLoaded, patientAttachments }) => {
  useEffect(() => {
    getPatientAttachmentsService(params.patientId)
      .then((data) => patientAttachmentsLoaded(data))
      .catch((err) => console.log(err));
  }, []);

  return (
    <div className="w-100 content-container">
      {patientAttachments ? (
        <>
          <div className="d-flex justify-content-between align-items-center">
            <h1 className="mb-5">Patient №{params.patientId} - Attachments</h1>
          </div>
          {patientAttachments.length > 0 ? (
            <PatientAttachmentsTable patientAttachments={patientAttachments} patientID={params.patientId}/>
          ) : (
            <div className="alert alert-danger text-center">
              <strong>Patient doesn`t have any attachments!</strong>
            </div>
          )}
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
};

const mapStateToProps = ({ patients: { patientAttachments } }) => ({
  patientAttachments,
});

const mapDispatchToProps = {
  patientAttachmentsLoaded,
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientAttachments);
