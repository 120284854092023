import React, { useEffect } from 'react'
import { connect } from 'react-redux';

import TherapistDetailsForm from '../../components/TherapistDetailsForm';
import Loader from '../../components/Loader';

import { getTherapistById } from '../../services/therapists/therapists';
import { getCurrentEditingTherapist,
    clearCurrentEditingTherapist } from '../../actions/therapists';


const TherapistDetails = ({ 
    match: { params }, 
    getCurrentEditingTherapist,
    clearCurrentEditingTherapist,
    currentEditingTherapist }) => {

    useEffect(() => {
        clearCurrentEditingTherapist();

        getTherapistById(params.therapistId)
            .then((data) => getCurrentEditingTherapist(data))
    }, []);

    return (
        <div className="w-100 content-container">
            {
                currentEditingTherapist ? (
                    <>
                        <div className="d-flex justify-content-between align-items-center">
                            <h1 className="mb-5">
                                {currentEditingTherapist.name ? `${currentEditingTherapist.name.first} ${currentEditingTherapist.name.last}` : 'Unnamed therapist'}
                            </h1>
                            <button
                                type="submit"
                                className="btn btn-primary"
                                form="therapist-details-form"
                            >
                                <span className="btn-label">
                                    <i className="fas fa-save mr-2"></i>
                                </span>
                                Save changes
                            </button>
                        </div>
                        <TherapistDetailsForm therapistId={params.therapistId} />
                    </>
                ) : <Loader />
            }
        </div>
    );
};

const mapStateToProps = ({ therapists: { currentEditingTherapist } }) => ({
    currentEditingTherapist
});

const mapDispatchToProps = {
    getCurrentEditingTherapist,
    clearCurrentEditingTherapist
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TherapistDetails);
