import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import dayjs from "dayjs";

import { deletePatientDiseaseItemService } from "../../services/patients/patients";
import confirmDeleteAction from "../../helpers/confirmDeleteAction";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

const { SearchBar } = Search;

const PatientDiseasesTable = ({ patientDiseasesList, diseasesList, deletePatientDiseaseItem, patientID }) => {
  const columns = [
    {
      dataField: "createdDate",
      text: "Created date",
      sort: true,
      formatter: (cell, row) => {
        return <>{dayjs(row.createdDate).format("YYYY-MM-DD")}</>;
      },
    },
    {
      dataField: "organization_id",
      text: "Organization ID",
      sort: true,
    },
    {
      dataField: "disease_id",
      text: "Disease",
      sort: false,
      formatter: (cell, row) => {
        return <>{diseasesList.find((item) => item._id === row.disease_id).name.en}</>;
      },
    },
    {
      dataField: "comment",
      text: "Additional comment",
      sort: true,
      formatter: (cell, row) => {
        return <>{row.comment ? row.comment : "-"}</>;
      },
    },
    {
      dataField: "action",
      text: "Actions",
      sort: false,
      formatter: (cell, row) => {
        return (
          <div className="d-flex justify-content-around">
            <button
              className="btn btn-danger btn-rounded w-100"
              onClick={() => {
                if (confirmDeleteAction()) {
                  deletePatientDiseaseItemService(patientID, row._id)
                    .then(() => deletePatientDiseaseItem(row._id))
                    .catch(() => console.log("deletePatientDiseaseItemService error"));
                }
              }}
            >
              <i className="far fa-trash-alt mr-3"></i>Delete
            </button>
          </div>
        );
      },
    },
  ];

  const defaultSorted = [
    {
      dataField: "organization_id",
      order: "desc",
    },
  ];

  return (
    <ToolkitProvider keyField="_id" bootstrap4 data={patientDiseasesList} columns={columns} search>
      {(props) => (
        <div>
          <div className="d-flex align-items-center">
            <p className="mb-2 mr-2">Search in table:</p>
            <SearchBar {...props.searchProps} />
          </div>
          <BootstrapTable
            bootstrap4
            defaultSorted={defaultSorted}
            pagination={paginationFactory()}
            noDataIndication="Nothing found. Try to change your request!"
            {...props.baseProps}
            wrapperClasses="table-responsive"
          />
        </div>
      )}
    </ToolkitProvider>
  );
};

export default PatientDiseasesTable;
