import React, { useState } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import ReactPlayer from "react-player";

import renderField from "../FormInputs/Field";
import renderMultiSelect from "../FormInputs/MultiSelect";

import { addLibraryAttachment, deleteLibraryAttachment } from "../../actions/libraries";
import {
   addLibraryService,
   addLibraryAttachmentsService,
   updateLibraryByIdService,
} from "../../services/libraries/libraries";

import libraryAdapter from "../../adapters/libraries";
import validate from "../../validation/library-form";

import "./styles.css";
import Loader from "../Loader";

const LibraryForm = (props) => {

   const {  handleSubmit,
      addLibraryAttachment,
      libraryAttachmentsList,
      deleteLibraryAttachment,
      libraryParametrs,
      initialValues,
      currentEditingLibrary,
      libraryId,
      setLoaderVisibilityStatus,
      history,
      LibraryForm,
      languagesList,
      submitFailed } = props;

   const [typeOfExerciseChecked, setTypeOfExerciseChecked] = useState(initialValues.typeOfExercise || null);

   const submitForm = (values) => {
      setLoaderVisibilityStatus(true);
      const libraryObj = libraryAdapter(values);
      if (currentEditingLibrary) {
         libraryObj.id = libraryId;
         updateLibraryByIdService(libraryObj)
            .then((data) => addLibraryAttachmentsService(data._id, libraryAttachmentsList))
            .then(() => {
               setLoaderVisibilityStatus(false);
               history.push("/libraries");
            });
      } else {
         addLibraryService(libraryObj)
            .then((data) => addLibraryAttachmentsService(data._id, libraryAttachmentsList))
            .then(() => {
               setLoaderVisibilityStatus(false);
               history.push("/libraries");
            });
      }
   };

   return (
      <form id="library-form" onSubmit={handleSubmit(submitForm)}>
         <div className="row">
            <div className="col">
               <div className="form-row">
                  <div className="form-group col-md-8">
                     <label>Language</label>
                     <Field name="language" component="select" className="form-control form-control-lg text-dark">
                        {languagesList
                           ? languagesList.map((item) => {
                                return (
                                   <option key={item._id} value={item._id}>
                                      {item.name}
                                   </option>
                                );
                             })
                           : null}
                     </Field>
                  </div>
               </div>
               <div className="form-row">
                  <Field
                     name="name"
                     component={renderField}
                     type="text"
                     label="Material name *"
                     placeholder="Enter material name"
                     containerClassName="form-group col-md-8"
                  />
               </div>
               <div className="form-row">
                  <Field
                     name="categories"
                     component={renderMultiSelect}
                     data={libraryParametrs.categories}
                     valueField="id"
                     textField="name"
                     label="Categories *"
                  />
               </div>
               <div className="form-row">
                  <Field
                     name="bodyRegion"
                     component={renderMultiSelect}
                     data={libraryParametrs.bodyRegions}
                     valueField="id"
                     textField="name"
                     label="Body region"
                  />
               </div>
               <div className="form-row">
                  <div className="form-group col-md-8">
                     <label>Objective</label>
                     <Field name="objective" component="select" className="form-control form-control-lg text-dark">
                        {libraryParametrs.objectives
                           ? libraryParametrs.objectives.map((item, index) => {
                                return (
                                   <option key={item.id} value={item.id}>
                                      {item.name}
                                   </option>
                                );
                             })
                           : null}
                     </Field>
                  </div>
               </div>
               <div className="form-row">
                  <div className="form-group col-md-8">
                     <label>Need PH help</label>
                     <div className="form-check">
                        <label>
                           <Field
                              name="needPHHelp"
                              component="input"
                              type="radio"
                              value="true"
                              className="form-check-input"
                           />
                           Yes
                        </label>
                     </div>
                     <div className="form-check">
                        <label>
                           <Field
                              name="needPHHelp"
                              component="input"
                              type="radio"
                              value="false"
                              className="form-check-input"
                           />
                           No
                        </label>
                     </div>
                  </div>
               </div>
               <div className="form-row">
                  {/* <div className="form-group col-md-8"> */}
                     <Field
                     name="equipment"
                     component={renderMultiSelect}
                     data={libraryParametrs.equipments}
                     valueField="id"
                     textField="name"
                     label="Equipment"
                  />
                     {/* <Field name="equipment" component="select" className="form-control form-control-lg text-dark">
                        {libraryParametrs.equipments
                           ? libraryParametrs.equipments.map((item) => {
                                return (
                                   <option key={item.id} value={item.id}>
                                      {item.name}
                                   </option>
                                );
                             })
                           : null}
                     </Field> */}
                  {/* </div> */}
               </div>
               <div className="form-row">
                  <div className="form-group col-md-10">
                     <label>Type of exercises</label>
                     <div className="form-check">
                        <label>
                           <Field
                              name="typeOfExercise"
                              component="input"
                              type="radio"
                              value="BY_QUANTITY"
                              className="form-check-input"
                              onClick={() => setTypeOfExerciseChecked("BY_QUANTITY")}
                           />
                           By quantity
                        </label>
                     </div>
                     {typeOfExerciseChecked === "BY_QUANTITY" ? (
                        <div className="form-row">
                           <div className="form-group col-md-4">
                              <label>Repetitions</label>
                              <Field
                                 name="repetition"
                                 component="input"
                                 type="text"
                                 className="form-control form-control-lg text-dark"
                              />
                           </div>
                           <div className="form-group col-md-4">
                              <label>Cycles</label>
                              <Field
                                 name="cycles"
                                 component="input"
                                 type="number"
                                 className="form-control form-control-lg text-dark"
                              />
                           </div>
                           <div className="form-group col-md-4">
                              <label>Rest between cycles</label>
                              <Field
                                 name="rest_between_cycles"
                                 component="input"
                                 type="text"
                                 className="form-control form-control-lg text-dark"
                              />
                           </div>
                        </div>
                     ) : null}
                     <div className="form-check">
                        <label>
                           <Field
                              name="typeOfExercise"
                              component="input"
                              type="radio"
                              value="BY_DURATION"
                              className="form-check-input"
                              onClick={() => setTypeOfExerciseChecked("BY_DURATION")}
                           />
                           By duration
                        </label>
                     </div>
                     {typeOfExerciseChecked === "BY_DURATION" ? (
                        <div className="form-row">
                           <div className="form-group col-md-4">
                              <label>Time</label>
                              <Field
                                 name="time"
                                 component="input"
                                 type="text"
                                 className="form-control form-control-lg text-dark"
                              />
                           </div>
                           <div className="form-group col-md-4">
                              <label>Cycles</label>
                              <Field
                                 name="cycles"
                                 component="input"
                                 type="number"
                                 className="form-control form-control-lg text-dark"
                              />
                           </div>
                           <div className="form-group col-md-4">
                              <label>Rest between cycles</label>
                              <Field
                                 name="rest_between_cycles"
                                 component="input"
                                 type="number"
                                 className="form-control form-control-lg text-dark"
                              />
                           </div>
                        </div>
                     ) : null}
                     <div className="form-check">
                        <label>
                           <Field
                              name="typeOfExercise"
                              component="input"
                              type="radio"
                              value="NO_FREQUENCY"
                              className="form-check-input"
                              onClick={() => setTypeOfExerciseChecked(null)}
                           />
                           No frequency
                        </label>
                     </div>
                  </div>
                  {
                     !LibraryForm?.values?.typeOfExercise && submitFailed && 
                  <div className="alert alert-danger mb-0 mt-1">{LibraryForm?.syncErrors?.typeOfExercise}</div>
                  }
               </div>
            </div>
            <div className="col">
               <div className="form-row">
                  <div className="form-group col-md-8">
                     <label>Description</label>
                     <Field
                        name="description"
                        component="textarea"
                        className="form-control form-control-lg text-dark"
                     />
                  </div>
               </div>
               <div className="form-row">
                  <div className="form-group col-md-8">
                     <button
                        className="btn btn-primary w-100"
                        type="button"
                        disabled={libraryAttachmentsList.length === 5 ? true : false}
                        onClick={() => {
                           const input = document.createElement("input");
                           input.type = "file";
                           input.accept = 'image/jpeg, image/png, application/pdf, application/vnd.ms-excel"';

                           input.onchange = (event) => {
                              const file = event.target.files[0];
                              addLibraryAttachment(file);
                           };

                           input.click();
                        }}
                     >
                        <i className="fas fa-plus mr-2"></i>
                        {libraryAttachmentsList.length === 5
                           ? "Maximum number of files reached"
                           : "Add attachment item"}
                     </button>
                  </div>
               </div>
               {libraryAttachmentsList.length > 0
                  ? libraryAttachmentsList.map((item) => {
                       return (
                          <div className="form-row" key={item._id}>
                             <div className="form-group col-md-8 d-flex align-items-center justify-content-between">
                                <div className="d-flex align-items-center attachment-container">
                                   {item.type ? (
                                      item.type.substr(0, 5) === "image" ? (
                                         <img src={item.blob} className="img-thumbnail w-25 h-25" />
                                      ) : (
                                         <div className="attachments-container-icon img-thumbnail">
                                            <i className="fas fa-file-signature ml-1"></i>
                                         </div>
                                      )
                                   ) : item.mimeType.substr(0, 5) === "image" ? (
                                      <img src={item.url} className="img-thumbnail w-25 h-25" />
                                   ) : (
                                      <div className="attachments-container-icon img-thumbnail">
                                         <i className="fas fa-file-signature ml-1"></i>
                                      </div>
                                   )}
                                   <p className="ml-2 mb-0">{item.name}</p>
                                </div>
                                <i className="fas fa-trash mr-2" onClick={() => deleteLibraryAttachment(item._id)}></i>
                             </div>
                          </div>
                       );
                    })
                  : null}
               <div className="form-row">
                  <div className="form-group col-md-8">
                     <label>Youtube link</label>
                     <Field
                        name="youtube"
                        component="input"
                        type="text"
                        className="form-control form-control-lg text-dark"
                     />
                     {LibraryForm && LibraryForm.syncErrors ? (
                        LibraryForm.syncErrors.youtube && LibraryForm.values.youtube ? (
                           <div className="alert alert-danger mb-0 mt-1">{LibraryForm.syncErrors.youtube}</div>
                        ) : null
                     ) : null}
                  </div>
               </div>
               {LibraryForm && !LibraryForm.syncErrors ? (
                  LibraryForm.values ? (
                     <div className="form-row">
                        <div className="form-group col-md-8" id="player">
                           <ReactPlayer url={LibraryForm.values.youtube} controls />
                        </div>
                     </div>
                  ) : (
                     <Loader />
                  )
               ) : null}
            </div>
         </div>
      </form>
   );
};

const mapStateToProps = ({
   libraries: { libraryAttachmentsList, currentEditingLibrary },
   lists: { libraryParams, languagesList },
   form: { LibraryForm },
}) => ({
   libraryAttachmentsList,
   libraryParams,
   currentEditingLibrary,
   LibraryForm,
   languagesList,
   initialValues: {
      objective: currentEditingLibrary
         ? currentEditingLibrary.objective
         : null,
      equipment: currentEditingLibrary
         ? currentEditingLibrary.equipment
         : libraryParams
         ? libraryParams.equipments[0].id
         : null,
      name: currentEditingLibrary ? currentEditingLibrary.name : null,
      description: currentEditingLibrary ? currentEditingLibrary.description : null,
      youtube: currentEditingLibrary ? currentEditingLibrary.youtube : null,
      typeOfExercise: currentEditingLibrary ? currentEditingLibrary.typeOfExercise : null,
      bodyRegion: currentEditingLibrary ? currentEditingLibrary.bodyRegion?.map((item) => item) : null,
      categories: currentEditingLibrary ? currentEditingLibrary.categories.map((item) => item) : null,
      needPHHelp: currentEditingLibrary ? `${currentEditingLibrary.needPHHelp}` : null,
      repetition: currentEditingLibrary ? currentEditingLibrary.repetition : null,
      cycles: currentEditingLibrary ? currentEditingLibrary.cycles : null,
      rest_between_cycles: currentEditingLibrary ? currentEditingLibrary.rest_between_cycles : null,
      time: currentEditingLibrary ? currentEditingLibrary.time : null,
      language: currentEditingLibrary
         ? currentEditingLibrary.language[0]._id
            : languagesList[0]._id
   },
});

const mapDispatchToProps = {
   addLibraryAttachment,
   deleteLibraryAttachment,
};

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({ form: "LibraryForm", validate })(LibraryForm));
