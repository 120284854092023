import config from "../config";

const getSubscriptionsService = async () => {
   try {
      const response = await fetch(`${config._BaseUrlWithoutAdmin}/subscription/all`, {
         method: "GET",
         headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${JSON.parse(localStorage.getItem("auth"))}`,
         },
      });

      if (response.status > 204) {
         throw new Error("GET subscriptions error");
      }

      return await response.json();
   } catch (error) {
      throw error;
   }
};

const deleteSubscriptionService = async (subscriptionID = "") => {
   try {
      const response = await fetch(`${config._BaseUrlWithoutAdmin}/subscription/${subscriptionID}`, {
         method: "DELETE",
         headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${JSON.parse(localStorage.getItem("auth"))}`,
         },
      });

      if (response.status > 204) {
         throw new Error("DELETE subscriptions error");
      }

      return await response.json();
   } catch (error) {
      throw error;
   }
};

const getSubscriptionByIDService = async (subscriptionID = "") => {
   try {
      const response = await fetch(`${config._BaseUrlWithoutAdmin}/subscription/${subscriptionID}`, {
         method: "GET",
         headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${JSON.parse(localStorage.getItem("auth"))}`,
         },
      });

      if (response.status > 204) {
         throw new Error("GET subscriptions by ID error");
      }

      return await response.json();
   } catch (error) {
      throw error;
   }
};

const updateSubscriptionItemService = async (subscriptionID, updatedSubscriptionObj) => {
   try {
      const response = await fetch(`${config._BaseUrlWithoutAdmin}/subscription/${subscriptionID}`, {
         method: "PUT",
         headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${JSON.parse(localStorage.getItem("auth"))}`,
         },
         body: JSON.stringify(updatedSubscriptionObj),
      });

      if (response.status > 204) {
         throw new Error("PUT subscription error");
      }

      return await response.json();
   } catch (error) {
      throw error;
   }
};

export {
   getSubscriptionsService,
   deleteSubscriptionService,
   getSubscriptionByIDService,
   updateSubscriptionItemService,
};
