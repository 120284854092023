import React, { useEffect } from 'react'
import { connect } from 'react-redux';

import LibraryForm from '../../components/LibraryForm';
import Loader from '../../components/Loader';

import { libraryParamsListLoaded } from '../../actions/lists';
import { libraryByIdLoaded } from '../../actions/libraries';
import { getLibraryParamsService } from '../../services/lists/lists';
import { setLoaderVisibilityStatus } from '../../actions/loader';

const NewLibrary = ({ history,
    libraryParamsListLoaded,
    libraryParams,
    libraryByIdLoaded,
    setLoaderVisibilityStatus,
    isVisibleLoader,
    languagesList
}) => {

    libraryByIdLoaded(null);

    useEffect(() => {
        getLibraryParamsService()
            .then((data) => libraryParamsListLoaded(data))
    }, []);

    return (
        <div className="w-100 content-container">
            <div className="d-flex align-items-center justify-content-between mb-5">
                <h1>New library</h1>
                <button
                    className="ml-5 btn btn-primary"
                    form="library-form">
                    {
                        isVisibleLoader ? <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span> : <i className="fas fa-save mr-2"></i>
                    }
                        Create new library item
                </button>
            </div>
            {
                libraryParams && languagesList ?
                    <LibraryForm
                        libraryParametrs={libraryParams}
                        setLoaderVisibilityStatus={setLoaderVisibilityStatus}
                        history={history}
                    /> : <Loader />
            }
        </div>
    );
};

const mapStateToProps = ({ lists: { libraryParams, languagesList }, loader: { isVisibleLoader } }) => ({
    libraryParams,
    isVisibleLoader,
    languagesList
});

const mapDispatchToProps = {
    libraryParamsListLoaded,
    libraryByIdLoaded,
    setLoaderVisibilityStatus
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NewLibrary);