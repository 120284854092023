const initState = {
  countriesList: null,
  languagesList: null,
  currenciesList: null,
  unitSystemList: null,
  libraryParams: null,
  diseasesList: null,
};

const countriesListLoaded = (state, action) => {
  return {
    ...state,
    countriesList: action.payload,
  };
};

const languagesListLoaded = (state, action) => {
  return {
    ...state,
    languagesList: action.payload,
  };
};

const currenciesListLoaded = (state, action) => {
  return {
    ...state,
    currenciesList: action.payload,
  };
};

const unitSystemsListLoaded = (state, action) => {
  return {
    ...state,
    unitSystemList: action.payload,
  };
};

const libraryParamsListLoaded = (state, action) => {
  return {
    ...state,
    libraryParams: action.payload,
  };
};

const diseasesListLoaded = (state, action) => {
  return {
    ...state,
    diseasesList: action.payload,
  };
};

const lists = (state = initState, action) => {
  switch (action.type) {
    case "COUNTRIES_LIST_LOADED":
      return countriesListLoaded(state, action);
    case "LANGUAGES_LIST_LOADED":
      return languagesListLoaded(state, action);
    case "CURRENCIES_LIST_LOADED":
      return currenciesListLoaded(state, action);
    case "UNIT_SYSTEMS_LIST_LOADED":
      return unitSystemsListLoaded(state, action);
    case "LIBRARY_PARAMS_LIST_LOADED":
      return libraryParamsListLoaded(state, action);
    case "DISEASES_LIST_LOADED":
      return diseasesListLoaded(state, action);
    default:
      return state;
  }
};

export default lists;
