import React from 'react'
import { Field, reduxForm } from 'redux-form'

import loginUserService from '../../services/auth/login';

import Logo from '../../assets/images/favicon.png'
import './styles.css'

const Login = ({ handleSubmit, history, setAvailableToken }) => {

    const submitForm = (values) => {
        loginUserService(values, history, setAvailableToken)
            .catch(()=> console.log('Login error'))
    }

    return (
        <div className="container h-100 ">
            <div className="d-flex justify-content-center h-100">
                <div className="user_card">
                    <div className="d-flex justify-content-center">
                        <div className="brand_logo_container">
                            <img src={Logo} className="brand_logo" alt="Logo" />
                        </div>
                    </div>
                    <div className="d-flex justify-content-center form_container">
                        <form onSubmit={handleSubmit(submitForm)}>
                            <div className="input-group mb-3">
                                <div className="input-group-append">
                                    <span className="input-group-text"><i className="fas fa-user"></i></span>
                                </div>
                                <Field
                                    name="email"
                                    component="input"
                                    type="text"
                                    placeholder="Email"
                                    className="form-control input_user"
                                />
                            </div>
                            <div className="input-group mb-2">
                                <div className="input-group-append">
                                    <span className="input-group-text"><i className="fas fa-key"></i></span>
                                </div>
                                <Field
                                    name="password"
                                    component="input"
                                    type="password"
                                    placeholder="Password"
                                    className="form-control input_user"
                                />
                            </div>
                            <div className="d-flex justify-content-center mt-3 login_container">
                                <button type="submit" name="button" className="btn login_btn">Login</button>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        </div >
    );
};

export default reduxForm({
    form: 'LoginForm'
})(Login)