const validate = (values) => {

    const errors = {};

    if (!values.name) {
        errors.name = 'Required field'
    }

    if (!values.categories) {
        errors.categories = 'Required field'
    }

    if (values.categories) {
        if (values.categories.length < 1) {
            errors.categories = 'Required field'
        }
    }

    // if (!values.bodyRegion) {
    //     errors.bodyRegion = 'Required field'
    // }

    // if (values.bodyRegion) {
    //     if (values.bodyRegion.length < 1) {
    //         errors.bodyRegion = 'Required field'
    //     }
    // }

    if(!values.typeOfExercise) {
        errors.typeOfExercise = 'Required field'
    }

    if (values.youtube) {
        if (!/http(?:s?):\/\/(?:www\.)?youtu(?:be\.com\/watch\?v=|\.be\/)([\w\-\_]*)(&(amp;)?‌​[\w\?‌​=]*)?/.test(values.youtube)) {
            errors.youtube = 'Invalid video url!'
        }
    }

    return errors
}

export default validate;