import React, { useEffect } from "react";
import { Field, reduxForm } from "redux-form";

import Loader from "../../components/Loader";
import SubscriptionsTable from "../../components/SubscriptionsTable";

import checkUserEmailInBlackList from "../../helpers/checkUserEmailInBlackList";
import { getSubscriptionsService } from "../../services/subscriptions/subscriptions";

const Subscriptions = ({ history, subscriptionsList, getSubscriptionsList }) => {
   useEffect(() => {
      if (!checkUserEmailInBlackList()) {
         getSubscriptionsService().then((data) => getSubscriptionsList(data.items));
      }
   }, []);

   if (checkUserEmailInBlackList()) {
      return (
         <div className="w-100 content-container">
            <div className="alert alert-danger text-center" role="alert">
               You don`t have access to view this list!
            </div>
         </div>
      );
   }

   return (
      <div className="w-100 content-container">
         {subscriptionsList ? (
            <>
               <div className="d-flex align-items-center justify-content-between mb-3">
                  <h1>Subscriptions</h1>
               </div>
               <SubscriptionsTable history={history} subscriptionsList={subscriptionsList} />
            </>
         ) : (
            <Loader />
         )}
      </div>
   );
};

export default reduxForm({ form: "SearchSubscriptionsField" })(Subscriptions);
