import config from '../config';

const setThumbnailService = async (blobImage, urlFlag, id) => {
    console.log(blobImage)
    const data = new FormData();
    data.append("thumbnail", blobImage);
    console.log(data)

    let imageUrl;

    if (urlFlag === 'organizationImage') {
        imageUrl = '/organization/thumbnail';
        data.append('organization_id', id)
    } else if (urlFlag === 'personalImage') {
        imageUrl = '/users/thumbnail';
        data.append('user_id', id)
    } else {
        imageUrl = `/patients/${id}/thumbnail`
    }

    try {
        const response = await fetch(`${config._BaseUrl}${imageUrl}`, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem('auth'))}`,
            },
            body: data
        })
        if (response.status > 204) {
            throw new Error
        }
    } catch (error) {
        throw new Error
    }
}

const deleteOrganizationThumbnailService = async (organizationId) => {
    try {
        await fetch(`${config._BaseUrl}/organization/thumbnail`, {
            method: "DELETE",
            headers: {
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem('auth'))}`,
            },
            body: JSON.stringify({ organization_id: organizationId })
        })
    } catch (error) {
        throw new Error
    }
}

const deletePatientThumbnailService = async (patientId) => {
    try {
        await fetch(`${config._BaseUrl}/patients/${patientId}/thumbnail`, {
            method: "DELETE",
            headers: {
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem('auth'))}`,
            },
        })
    } catch (error) {
        throw new Error
    }
}

const deleteTherapistThumbnailService = async (therapistId) => {
    try {
        await fetch(`${config._BaseUrl}/users/thumbnail`, {
            method: "DELETE",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem('auth'))}`,
            },
            body: JSON.stringify({ user_id: therapistId })
        })
    } catch (error) {
        throw new Error
    }
}

export {
    setThumbnailService,
    deleteOrganizationThumbnailService,
    deletePatientThumbnailService,
    deleteTherapistThumbnailService
}