// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/empty-image.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".image-container {\n  display: flex;\n  align-items: center;\n}\n\n.image-preview-container {\n  box-sizing: border-box;\n  height: 76px;\n  width: 76px;\n  border: 1px solid grey;\n  border-radius: 50%;\n  background-color: #f5f6f9;\n  margin-right: 24px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.image-preview {\n  height: 100%;\n  width: 100%;\n  border-radius: 50%;\n}\n\n#organization-image, #patient-image, #therapist-image {\n  display: none;\n}\n\n.empty-image-icon {\n  display: block;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat center;\n  width: 20px;\n  height: 18px;\n}\n", ""]);
// Exports
module.exports = exports;
