import React from 'react'
import Login from '../components/Login';

import './styles.css';

const LoginPage = ({ history, setAvailableToken }) => {
    return (
        <div className="full-size-container d-flex justify-content-center" >
            <Login history={history} setAvailableToken={setAvailableToken}/>
        </div>
    );
};

export default LoginPage;