const getLibrariesList = (data) => {
    return {
        type: 'LIBRARIES_WAS_LOADED',
        payload: data
    };
};

const addLibraryAttachment = (data) => {
    return {
        type: 'ADD_LIBRARY_ATTACHMENT',
        payload: data
    };
};

const deleteLibraryAttachment = (data) => {
    return {
        type: 'DELETE_LIBRARY_ATTACHMENT',
        payload: data
    };
};

const deleteLibrary = (libraryId) => {
    return {
        type: 'DELETE_LIBRARY',
        payload: libraryId
    };
};

const libraryByIdLoaded = (libraryInfo) => {
    return {
        type: 'LIBRARY_BY_ID_LOADED',
        payload: libraryInfo
    };
};

export {
    getLibrariesList,
    addLibraryAttachment,
    deleteLibraryAttachment,
    deleteLibrary,
    libraryByIdLoaded
}