import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";

import LibrariesTable from "../../components/LibrariesTable";
import Loader from "../../components/Loader";

import { getLibrariesList, deleteLibrary } from "../../actions/libraries";
import { getLibrariesService } from "../../services/libraries/libraries";

const Libraries = ({ history, librariesList, getLibrariesList, deleteLibrary }) => {
  useEffect(() => {
    getLibrariesService().then((data) => getLibrariesList(data.items));
  }, []);

  return (
    <div className="w-100 content-container">
      {librariesList ? (
        <>
          <div className="d-flex align-items-center justify-content-between mb-3">
            <h1>Libraries</h1>
            <button className="ml-5 btn btn-primary" onClick={() => history.push("libraries/new")}>
              <i className="fas fa-folder-plus mr-2"></i>
              New library item
            </button>
          </div>
          <LibrariesTable history={history} librariesList={librariesList} deleteLibrary={deleteLibrary} />
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
};

const mapStateToProps = ({ libraries: { librariesList } }) => ({
  librariesList,
});

const mapDispatchToProps = {
  getLibrariesList,
  deleteLibrary,
};

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({ form: "SearchLibraryField" })(Libraries));
