import { connect } from "react-redux";

import PatientAppointmentsTable from "./PatientAppointmentsTable";

import { deletePatientAppointmentItem } from '../../actions/patients';

const mapDispatchToProps = {
    deletePatientAppointmentItem
};

export default connect(null, mapDispatchToProps)(PatientAppointmentsTable);
