import { connect } from "react-redux";

import PlanDetailsForm from "./PlanDetailsForm";

import { setLoaderVisibilityStatus } from "../../actions/loader";
import PLAN_DURATION from "../../constants/parametrs/plan";

const mapStateToProps = ({ plans: { currentEditingPlan } }) => ({
   currentEditingPlan,
   initialValues: {
      title_uk: currentEditingPlan ? currentEditingPlan.title.uk : "",
      title_en: currentEditingPlan ? currentEditingPlan.title.en : "",
      title_ru: currentEditingPlan ? currentEditingPlan.title.ru : "",
      USD: currentEditingPlan ? currentEditingPlan.price.USD : "",
      EUR: currentEditingPlan ? currentEditingPlan.price.EUR : "",
      RUB: currentEditingPlan ? currentEditingPlan.price.RUB : "",
      UAH: currentEditingPlan ? currentEditingPlan.price.UAH : "",
      description_uk: currentEditingPlan ? currentEditingPlan.description.uk : "",
      description_en: currentEditingPlan ? currentEditingPlan.description.en : "",
      description_ru: currentEditingPlan ? currentEditingPlan.description.ru : "",
      duration: currentEditingPlan ? PLAN_DURATION.find((item)=> item.duration === currentEditingPlan.duration).duration : PLAN_DURATION[0],
      countOfPatients: currentEditingPlan ? currentEditingPlan.countOfPatients : 1,
      countOfTherapists: currentEditingPlan ? currentEditingPlan.countOfTherapists : 1,
      isVisible: currentEditingPlan ? currentEditingPlan.isVisible : false
   },
});

const mapDispatchToProps = {
   setLoaderVisibilityStatus, 
};

export default connect(mapStateToProps, mapDispatchToProps)(PlanDetailsForm);
