import { connect } from "react-redux";

import PatientDiseasesTable from "./PatientDiseasesTable";

import { deletePatientDiseaseItem } from '../../actions/patients';

const mapDispatchToProps = {
    deletePatientDiseaseItem
};

export default connect(null, mapDispatchToProps)(PatientDiseasesTable);
