const createPlanObj = (formValues) => {
  const values = { ...formValues };
  const planObj = {
    title: {},
    description: {},
    price: {},
  };

  Object.keys(values).forEach((item) => {
    if (item.includes("title")) {
      planObj.title[`${item.slice(-2)}`] = values[item];
    } else if (item.includes("description")) {
      planObj.description[`${item.slice(-2)}`] = values[item];
    } else if (item.includes("USD") || item.includes("RUB") || item.includes("EUR") || item.includes("UAH")) {
      planObj.price[`${item}`] = values[item];
    } else if (item.includes("duration")) {
      planObj[`${item}`] = parseInt(values[item].duration) || parseInt(values[item]);
    } else {
      planObj[`${item}`] = values[item];
    }
  });

  return planObj;
};

export default createPlanObj;
