import { connect } from "react-redux";

import PatientAttachmentsTable from "./PatientAttachmentsTable";

import { deletePatientAttachmentItem } from '../../actions/patients';

const mapDispatchToProps = {
    deletePatientAttachmentItem
};

export default connect(null, mapDispatchToProps)(PatientAttachmentsTable);
