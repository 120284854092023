import React, { useEffect } from "react";
import { connect } from "react-redux";

import PatientAppointmentsTable from "../../components/PatientAppointmentsTable";

import { getPatientAppointmentsService } from "../../services/patients/patients";
import { patientAppointmentsLoaded } from "../../actions/patients";

import Loader from "../../components/Loader";

const PatientAppointments = ({ match: { params }, patientAppointmentsLoaded, patientAppointments }) => {
  useEffect(() => {
    getPatientAppointmentsService(params.patientId)
      .then((data) => patientAppointmentsLoaded(data))
      .catch((err) => console.log(err));
  }, []);

  return (
    <div className="w-100 content-container">
      {patientAppointments ? (
        <>
          <div className="d-flex justify-content-between align-items-center">
            <h1 className="mb-5">Patient №{params.patientId} - Appointments</h1>
          </div>
          {patientAppointments.length > 0 ? (
            <PatientAppointmentsTable patientAppointments={patientAppointments} />
          ) : (
            <div className="alert alert-danger text-center">
              <strong>Patient doesn`t have any appointments!</strong>
            </div>
          )}
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
};

const mapStateToProps = ({ patients: { patientAppointments } }) => ({
  patientAppointments,
});

const mapDispatchToProps = {
  patientAppointmentsLoaded,
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientAppointments);
