import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search, CSVExport } from "react-bootstrap-table2-toolkit";

import { deletePlanItemService } from "../../services/plans/plans";
import confirmDeleteAction from "../../helpers/confirmDeleteAction";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

const { SearchBar } = Search;
const { ExportCSVButton } = CSVExport;

const PlansTable = ({ history, plansList, deletePlan }) => {
  const columns = [
    {
      dataField: "title",
      text: "Plan title",
      sort: false,
      formatter: (cell, row) => {
        return <>{row.title.en}</>;
      },
    },
    {
      dataField: "_id",
      text: "Plan ID",
      sort: true,
    },
    {
      dataField: "description",
      text: "Plan description",
      sort: false,
      formatter: (cell, row) => {
        return <>{row.description.en}</>;
      },
    },
    {
      dataField: "countOfPatients",
      text: "Count of patients",
      sort: true,
    },
    {
      dataField: "countOfTherapists",
      text: "Count of therapists",
      sort: true,
    },
    {
      dataField: "action",
      text: "Actions",
      sort: false,
      formatter: (cell, row) => {
        return (
          <div className="d-flex justify-content-around">
            <button
              className="btn btn-warning btn-rounded w-100 mr-2"
              onClick={() => history.push(`/plans/id/${row._id}`)}
            >
              <i className="far fa-edit mr-2"></i>Edit
            </button>
            {!row.default && (
              <button
                className="btn btn-danger btn-rounded w-50"
                onClick={() => {
                  if (confirmDeleteAction()) {
                    deletePlanItemService(row._id)
                      .then(() => deletePlan(row._id))
                      .catch(() => console.log("deletePlanItemService error"));
                  }
                }}
              >
                <i className="far fa-trash-alt"></i>Delete
              </button>
            )}
          </div>
        );
      },
    },
  ];

  const defaultSorted = [
    {
      dataField: "title",
      order: "desc",
    },
  ];

  return (
    <ToolkitProvider keyField="_id" bootstrap4 data={plansList} columns={columns} search>
      {(props) => (
        <div>
          <div className="d-flex align-items-center">
            <ExportCSVButton {...props.csvProps} className="btn btn-success btn-rounded mb-2 mr-4">
              <i className="fas fa-file-download mr-2"></i>
              Export CSV
            </ExportCSVButton>
            <p className="mb-2 mr-2">Search in table:</p>
            <SearchBar {...props.searchProps} />
          </div>
          <BootstrapTable
            bootstrap4
            defaultSorted={defaultSorted}
            pagination={paginationFactory()}
            noDataIndication="Nothing found. Try to change your request!"
            {...props.baseProps}
            wrapperClasses="table-responsive"
          />
        </div>
      )}
    </ToolkitProvider>
  );
};

export default PlansTable;
