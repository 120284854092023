import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Provider } from "react-redux";

import { store } from "./services/store";
import { refreshToken } from "./services/token-handler/token-handler";

const EntryPoint = () => {
   // let token = JSON.parse(localStorage.getItem("auth"));
   // if (token) {
   //    refreshToken();
   //    setInterval(() => {
   //       refreshToken();
   //    }, 240000);
   // }

   return (
      <Provider store={store}>
         <App />
      </Provider>
   );
};
const rootElement = document.getElementById("root");

ReactDOM.render(<EntryPoint />, rootElement);
