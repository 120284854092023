import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import dayjs from "dayjs";

import { getBlobFiles } from "../../services/files";
import { deletePatientAttachmentItemService } from "../../services/patients/patients";
import removeDomainNameFromUrl from "../../adapters/attachments-url-parser";
import confirmDeleteAction from "../../helpers/confirmDeleteAction";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

const { SearchBar } = Search;

const PatientAttachmentsTable = ({ patientAttachments, deletePatientAttachmentItem, patientID }) => {
  const columns = [
    {
      dataField: "date",
      text: "Upload date",
      sort: false,
      formatter: (cell, row) => {
        return <>{dayjs(row.date.upload).format("YYYY-MM-DD")}</>;
      },
    },
    {
      dataField: "organization_id",
      text: "Organization ID",
      sort: true,
    },
    {
      dataField: "originalName",
      text: "File name",
      sort: true,
    },
    {
      dataField: "mimeType",
      text: "Type",
      sort: true,
    },
    {
      dataField: "action",
      text: "Actions",
      sort: false,
      formatter: (cell, row) => {
        return (
          <div className="d-flex justify-content-around">
            <button
              className="btn btn-success btn-rounded w-75 mr-2"
              onClick={() => {
                getBlobFiles(row.url).then((blob) => {
                  let url = window.URL.createObjectURL(blob);
                  const fileName = row.url.split("/");
                  let a = document.createElement("a");
                  a.href = url;
                  a.download = fileName[fileName.length - 1];
                  a.click();
                });
              }}
            >
              <i className="fas fa-download mr-2"></i>Download
            </button>
            <button
              className="btn btn-danger btn-rounded"
              onClick={() => {
                if (confirmDeleteAction()) {
                  deletePatientAttachmentItemService(patientID, { url: removeDomainNameFromUrl(row.url) })
                    .then(() => deletePatientAttachmentItem(row._id))
                    .catch(() => console.log("deletePatientAttachmentItemService error"));
                }
              }}
            >
              <i className="far fa-trash-alt"></i>Delete
            </button>
          </div>
        );
      },
    },
  ];

  const defaultSorted = [
    {
      dataField: "organization_id",
      order: "desc",
    },
  ];

  return (
    <ToolkitProvider keyField="_id" bootstrap4 data={patientAttachments} columns={columns} search>
      {(props) => (
        <div>
          <div className="d-flex align-items-center">
            <p className="mb-2 mr-2">Search in table:</p>
            <SearchBar {...props.searchProps} />
          </div>
          <BootstrapTable
            bootstrap4
            defaultSorted={defaultSorted}
            pagination={paginationFactory()}
            noDataIndication="Nothing found. Try to change your request!"
            {...props.baseProps}
            wrapperClasses="table-responsive"
          />
        </div>
      )}
    </ToolkitProvider>
  );
};

export default PatientAttachmentsTable;
