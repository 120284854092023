import config from "../config";

const baseUrl = config._BaseUrlWithoutAdmin;

const getCountriesListService = async () => {
  const response = await fetch(`${baseUrl}/countries`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${JSON.parse(localStorage.getItem("auth"))}`,
    },
  });
  return await response.json();
};

const getLanguagesListService = async () => {
  const response = await fetch(`${baseUrl}/languages/`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${JSON.parse(localStorage.getItem("auth"))}`,
    },
  });
  return await response.json();
};

const getCurrenciesListService = async () => {
  const response = await fetch(`${baseUrl}/currencies/`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${JSON.parse(localStorage.getItem("auth"))}`,
    },
  });
  return await response.json();
};

const getUnitSystemsListService = async () => {
  const response = await fetch(`${baseUrl}/unit_systems/`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${JSON.parse(localStorage.getItem("auth"))}`,
    },
  });
  return await response.json();
};

const getLibraryParamsService = async () => {
  const response = await fetch(`${baseUrl}/library/params`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${JSON.parse(localStorage.getItem("auth"))}`,
    },
  });
  return await response.json();
};

const getDiseasesListService = async () => {
  const response = await fetch(`${baseUrl}/diseases`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${JSON.parse(localStorage.getItem("auth"))}`,
    },
  });
  return await response.json();
};

export {
  getCountriesListService,
  getLanguagesListService,
  getCurrenciesListService,
  getUnitSystemsListService,
  getLibraryParamsService,
  getDiseasesListService,
};
