import config from "../config";

const getPatientsService = async () => {
  try {
    const response = await fetch(`${config._BaseUrl}/patients/all_patients`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("auth"))}`,
      },
    });

    if (response.status > 204) {
      throw new Error("GET organizations error");
    }

    return await response.json();
  } catch (error) {
    throw new Error("GET organizations error");
  }
};

const deletePatientService = async (patientId) => {
  try {
    const response = await fetch(`${config._BaseUrl}/patients/${patientId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("auth"))}`,
      },
    });

    if (response.status > 204) {
      throw new Error("DELETE patient error");
    }

    return await response.json();
  } catch (error) {
    throw new Error("DELETE patient error");
  }
};

const getPatientByIdService = async (patientId) => {
  try {
    const response = await fetch(`${config._BaseUrlWithoutAdmin}/patients/${patientId}/main_details`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("auth"))}`,
      },
    });

    if (response.status > 204) {
      throw new Error("GET pateint by ID error");
    }

    return await response.json();
  } catch (error) {
    throw new Error("GET pateint by ID error");
  }
};

const updatePatientByIdService = async (patientId, newPatientInfo) => {
  try {
    const response = await fetch(`${config._BaseUrl}/patients/${patientId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("auth"))}`,
      },
      body: JSON.stringify(newPatientInfo),
    });

    if (response.status > 204) {
      throw new Error("UPDATE patient error");
    }

    return await response.json();
  } catch (error) {
    throw new Error("UPDATE patient error");
  }
};

const getPatientDiseasesService = async (patientId) => {
  try {
    const response = await fetch(`${config._BaseUrl}/patients/${patientId}/diseases/all`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("auth"))}`,
      },
    });

    if (response.status > 204) {
      throw new Error("GET patient diseases error");
    }

    return await response.json();
  } catch (error) {
    throw error;
  }
};

const getPatientNotesService = async (patientId) => {
  try {
    const response = await fetch(`${config._BaseUrl}/patients/${patientId}/notes/all`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("auth"))}`,
      },
    });

    if (response.status > 204) {
      throw new Error("GET patient notes error");
    }

    return await response.json();
  } catch (error) {
    throw error;
  }
};

const getPatientAttachmentsService = async (patientId) => {
  try {
    const response = await fetch(`${config._BaseUrl}/patients/${patientId}/attachments/all`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("auth"))}`,
      },
    });

    if (response.status > 204) {
      throw new Error("GET patient attachments error");
    }

    return await response.json();
  } catch (error) {
    throw error;
  }
};

const getPatientAssessmentFormsService = async (patientId) => {
  try {
    const response = await fetch(`${config._BaseUrlWithoutAdmin}/inspections/patient/${patientId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("auth"))}`,
      },
    });

    if (response.status > 204) {
      throw new Error("GET patient assessment forms error");
    }

    return await response.json();
  } catch (error) {
    throw error;
  }
};

const getPatientAppointmentsService = async (patientId) => {
  try {
    const response = await fetch(`${config._BaseUrl}/patients/${patientId}/appointments/all`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("auth"))}`,
      },
    });

    if (response.status > 204) {
      throw new Error("GET patient appointments error");
    }

    return await response.json();
  } catch (error) {
    throw error;
  }
};

const getPatientHomeworksService = async (patientID) => {
  try {
    const response = await fetch(`${config._BaseUrlWithoutAdmin}/patients/${patientID}/homeworks/all`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("auth"))}`,
      },
    });

    if (response.status > 204) {
      throw new Error("GET patient homeworks error");
    }

    return await response.json();
  } catch (error) {
    throw error;
  }
};

const deletePatientNoteItemService = async (noteID) => {
  try {
    const response = await fetch(`${config._BaseUrl}/patients/${noteID}/notes`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("auth"))}`,
      },
    });

    if (response.status > 204) {
      throw new Error("DELETE patient note error");
    }

    return await response.json();
  } catch (error) {
    throw error;
  }
};

const deletePatientAttachmentItemService = async (patientID, attachmentUrlObj) => {
  try {
    const response = await fetch(`${config._BaseUrlWithoutAdmin}/patients/${patientID}/attachments`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("auth"))}`,
      },
      body: JSON.stringify(attachmentUrlObj),
    });

    if (response.status > 204) {
      throw new Error("DELETE patient attachment error");
    }

    return await response.json();
  } catch (error) {
    throw error;
  }
};

const deletePatientAssessmentFormItemService = async (assessmentFormID) => {
  try {
    const response = await fetch(`${config._BaseUrlWithoutAdmin}/inspections/${assessmentFormID}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("auth"))}`,
      },
    });

    if (response.status > 204) {
      throw new Error("DELETE patient assessment form error");
    }

    return await response.json();
  } catch (error) {
    throw error;
  }
};

const deletePatientAppointmentItemService = async (appointmentID) => {
  try {
    const response = await fetch(`${config._BaseUrlWithoutAdmin}/appointments/${appointmentID}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("auth"))}`,
      },
    });

    if (response.status > 204) {
      throw new Error("DELETE patient appointment error");
    }

    return await response.json();
  } catch (error) {
    throw error;
  }
};

const deletePatientDiseaseItemService = async (patientID, diseaseID) => {
  try {
    const response = await fetch(`${config._BaseUrlWithoutAdmin}/patients/${patientID}/diseases/${diseaseID}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("auth"))}`,
      },
    });

    if (response.status > 204) {
      throw new Error("DELETE patient disease error");
    }

    return await response.json();
  } catch (error) {
    throw error;
  }
};

const deletePatientHomeworkItemService = async (homeworkID) => {
  try {
    const response = await fetch(`${config._BaseUrlWithoutAdmin}/homework/${homeworkID}/`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("auth"))}`,
      },
    });

    if (response.status > 204) {
      throw new Error("DELETE patient homework error");
    }

    return await response.json();
  } catch (error) {
    throw error;
  }
};

export {
  getPatientsService,
  getPatientByIdService,
  getPatientDiseasesService,
  getPatientNotesService,
  getPatientAttachmentsService,
  getPatientAssessmentFormsService,
  getPatientAppointmentsService,
  getPatientHomeworksService,
  deletePatientService,
  deletePatientNoteItemService,
  deletePatientAttachmentItemService,
  deletePatientAssessmentFormItemService,
  deletePatientAppointmentItemService,
  deletePatientDiseaseItemService,
  deletePatientHomeworkItemService,
  updatePatientByIdService,
};
