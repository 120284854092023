const getOrganizationsList = (data) => {
    return {
        type: 'ORGANIZATIONS_WAS_LOADED',
        payload: data
    };
};

const deleteOrganization = (organizationId) => {
    return {
        type: 'DELETE_ORGANIZATION',
        payload: organizationId
    };
};

const setCurrentEditingOrganization  = (data) => {
    return {
        type: 'SET_CURRENT_EDITING_ORGANIZATION',
        payload: data
    };
};

const clearCurrentOrganization  = () => {
    return {
        type: 'CLEAR_CURRENT_ORGANIZATION'
    };
};

const updateCurrentOrganization  = (updatedData) => {
    return {
        type: 'UPDATE_CURRENT_ORGANIZATION',
        payload: updatedData
    };
};

export {
    getOrganizationsList,
    deleteOrganization,
    setCurrentEditingOrganization,
    clearCurrentOrganization,
    updateCurrentOrganization
}