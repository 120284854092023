import config from '../config';

const getTherapistsService = async () => {

    try {
        const response = await fetch(`${config._BaseUrl}/users/all_therapists`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem('auth'))}`
            }
        });

        if(response.status > 204) {
            throw new Error('GET therapists error')
        }

        return await response.json();
    } catch (error) {
        throw new Error('GET therapists error')
    }
}

const getTherapistById = async (therapistId) => {

    try {
        const response = await fetch(`${config._BaseUrl}/users/${therapistId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem('auth'))}`
            }
        });

        if(response.status > 204) {
            throw new Error('GET organization error')
        }

        return await response.json();
    } catch (error) {
        throw new Error('GET organization error')
    }
}

const deleteTherapistService = async (therapistId) => {

    try {
        const response = await fetch(`${config._BaseUrl}/users/${therapistId}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem('auth'))}`
            },
        });

        if(response.status > 204) {
            throw new Error('DELETE organizations error')
        }

        return await response.json();
    } catch (error) {
        throw new Error('DELETE organizations error')
    }
}

const updateTherapistByIdService = async (therapistId, newTherapistInfo) => {

    try {
        const response = await fetch(`${config._BaseUrl}/users/${therapistId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem('auth'))}`
            },
            body: JSON.stringify(newTherapistInfo)
        });

        if (response.status > 204) {
            throw new Error('UPDATE therapist error')
        }

        return await response.json();
    } catch (error) {
        throw new Error('UPDATE therapist error')
    }
}

export { 
    getTherapistsService,
    getTherapistById,
    deleteTherapistService,
    updateTherapistByIdService
}