import React, { useState } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { Link } from "react-router-dom";

import { setThumbnailService, deletePatientThumbnailService } from "../../services/thumbnail/thumbnail";
import { updatePatientByIdService } from "../../services/patients/patients";

const PatientDetailsForm = ({ patientId, currentEditingPatient, handleSubmit }) => {
  const [image, setNewImage] = useState(currentEditingPatient.thumbnail || null);

  const fileChangedHandler = (event) => {
    const file = event.target.files[0];
    const img = URL.createObjectURL(file);
    setThumbnailService(file, "", patientId)
      .then(() => setNewImage(img))
      .catch(() => console.log("Organization thumbnail error"));
  };

  const submitForm = (values) => {
    values.date = {
      birthday: new Date(values.birthday),
    };

    updatePatientByIdService(patientId, values);
  };

  return (
    <form id="patient-details-form" onSubmit={handleSubmit(submitForm)}>
      <div className="form-row">
        <div className="form-group col-md-12">
          <label>Patient thumbnail</label>
          <div className="image-container">
            <div className="image-preview-container">
              {(image && (
                <img
                  src={image.substring(0, 4) === "blob" ? image : `${currentEditingPatient.thumbnail}`}
                  className="image-preview"
                />
              )) || <i className="empty-image-icon" />}
            </div>
            <label className="btn btn-primary mb-0 mr-4" htmlFor="patient-image">
              Upload image
            </label>
            <input type="file" accept="image/*" id="patient-image" onChange={(e) => fileChangedHandler(e)}></input>
            {(image && (
              <div
                type="file"
                className="btn btn-danger"
                onClick={() => {
                  deletePatientThumbnailService(patientId);
                  setNewImage(null);
                  document.getElementById("patient-image").value = null;
                }}
              >
                Delete image
              </div>
            )) ||
              null}
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="form-group col-md-4">
          <label>Email</label>
          <Field
            name="email"
            component="input"
            type="text"
            className="form-control form-control-lg text-dark"
            disabled
          />
        </div>
        <div className="form-group col-md-4">
          <label>Phone</label>
          <Field
            name="phone"
            component="input"
            type="number"
            placeholder="Enter patient phone"
            className="form-control form-control-lg text-dark"
          />
        </div>
        <div className="form-group col-md-4">
          <label>Birthday</label>
          <Field
            name="birthday"
            component="input"
            type="date"
            className="form-control form-control-lg text-dark"
            min="1955-01-01"
            max="2005-12-31"
          />
        </div>
      </div>
      <div className="form-row">
        <div className="form-group col-md-4">
          <label>City</label>
          <Field name="city" component="input" type="text" className="form-control form-control-lg text-dark" />
        </div>
        <div className="form-group col-md-4">
          <label>ZIP</label>
          <Field
            name="zip"
            component="input"
            type="number"
            placeholder="Enter patient zip"
            className="form-control form-control-lg text-dark"
          />
        </div>
        <div className="form-group col-md-4">
          <label>Gender</label>
          <Field name="gender" component="select" className="form-control form-control-lg text-dark">
            <option value="male">Male</option>
            <option value="female">Female</option>
            <option value="other">Other</option>
          </Field>
        </div>
      </div>
      <div className="form-row">
        <div className="form-group col-md-4">
          <label>Kind of sports</label>
          <Field name="kindOfSports" component="input" type="text" className="form-control form-control-lg text-dark" />
        </div>
        <div className="form-group col-md-4">
          <label>Insurance</label>
          <Field
            name="insurance"
            component="input"
            type="text"
            placeholder="Enter patient insurance"
            className="form-control form-control-lg text-dark"
          />
        </div>
        <div className="form-group col-md-4">
          <label>Activity level</label>
          <Field name="activityLevel" component="select" className="form-control form-control-lg text-dark">
            <option value="0">Inactive</option>
            <option value="1">Sedentary</option>
            <option value="2">Moderately active</option>
            <option value="3">Very active</option>
            <option value="4">Extremely active</option>
          </Field>
        </div>
      </div>
      <div className="form-row">
        <div className="form-group col-md-4">
          <Link to={`${patientId}/diseases`} className="btn btn-primary w-100">
            <span className="btn-label">
              <i className="fas fa-notes-medical mr-2"></i>
            </span>
            Diseases
          </Link>
        </div>
        <div className="form-group col-md-4">
          <Link to={`${patientId}/notes`} className="btn btn-primary w-100">
            <span className="btn-label">
              <i className="fas fa-sticky-note mr-2"></i>
            </span>
            Notes
          </Link>
        </div>
        <div className="form-group col-md-4">
          <Link to={`${patientId}/attachments`} className="btn btn-primary w-100">
            <span className="btn-label">
              <i className="fas fa-paperclip mr-2"></i>
            </span>
            Attachments
          </Link>
        </div>
      </div>
      <div className="form-row">
        <div className="form-group col-md-4">
          <Link to={`${patientId}/assessment-forms`} className="btn btn-warning w-100">
            <span className="btn-label">
              <i className="fas fa-child mr-2"></i>
            </span>
            Assessment forms
          </Link>
        </div>
        <div className="form-group col-md-4">
          <Link to={`${patientId}/appointments`} className="btn btn-warning w-100">
            <span className="btn-label">
              <i className="fas fa-calendar-week mr-2"></i>
            </span>
            Schedule
          </Link>
        </div>
        <div className="form-group col-md-4">
          <Link to={`${patientId}/homeworks`} className="btn btn-warning w-100">
            <span className="btn-label">
              <i className="fas fa-calendar-week mr-2"></i>
            </span>
            Homeworks
          </Link>
        </div>
      </div>
    </form>
  );
};

const mapStateToProps = ({ patients: { currentEditingPatient } }) => ({
  currentEditingPatient,
  initialValues: {
    email: currentEditingPatient.email,
    phone: currentEditingPatient.phone,
    birthday: `${new Date(currentEditingPatient.date.birthday).getFullYear()}-${
      new Date(currentEditingPatient.date.birthday).getMonth() <= 9
        ? `${new Date(currentEditingPatient.date.birthday).getMonth()}` != 0
          ? `0${new Date(currentEditingPatient.date.birthday).getMonth()}`
          : `${new Date(currentEditingPatient.date.birthday).getMonth()}` + 1
        : new Date(currentEditingPatient.date.birthday).getMonth()
    }-${
      new Date(currentEditingPatient.date.birthday).getDate() <= 9
        ? `0${new Date(currentEditingPatient.date.birthday).getDate()}`
        : new Date(currentEditingPatient.date.birthday).getDate()
    }`,
    gender: currentEditingPatient.gender,
    city: currentEditingPatient.city,
    zip: currentEditingPatient.zip,
    activityLevel: currentEditingPatient.activityLevel,
    kindOfSports: currentEditingPatient.kindOfSports,
    insurance: currentEditingPatient.insurance,
  },
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({ form: "PatientDetailsForm" })(PatientDetailsForm));
