import config from "../config";

const getPlansService = async () => {
   try {
      const response = await fetch(`${config._BaseUrlWithoutAdmin}/plans/all`, {
         method: "GET",
         headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${JSON.parse(localStorage.getItem("auth"))}`,
         },
      });

      if (response.status > 204) {
         throw new Error("GET plans error");
      }

      return await response.json();
   } catch (error) {
      throw error;
   }
};

const getPlanByIDService = async (planID = "") => {
   try {
      const response = await fetch(`${config._BaseUrlWithoutAdmin}/plans/${planID}`, {
         method: "GET",
         headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${JSON.parse(localStorage.getItem("auth"))}`,
         },
      });

      if (response.status > 204) {
         throw new Error("GET plan by ID error");
      }

      return await response.json();
   } catch (error) {
      throw error;
   }
};

const createNewPlanItemService = async (planObj) => {
   try {
      const response = await fetch(`${config._BaseUrlWithoutAdmin}/plans`, {
         method: "POST",
         headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${JSON.parse(localStorage.getItem("auth"))}`,
         },
         body: JSON.stringify(planObj),
      });

      if (response.status > 204) {
         throw new Error("POST plan error");
      }

      return await response.json();
   } catch (error) {
      throw error;
   }
};

const updatePlanItemService = async (planObj) => {
    try {
       const response = await fetch(`${config._BaseUrlWithoutAdmin}/plans`, {
          method: "PUT",
          headers: {
             "Content-Type": "application/json",
             Authorization: `Bearer ${JSON.parse(localStorage.getItem("auth"))}`,
          },
          body: JSON.stringify(planObj),
       });
 
       if (response.status > 204) {
          throw new Error("PUT plan error");
       }
 
       return await response.json();
    } catch (error) {
       throw error;
    }
 };

const deletePlanItemService = async (planID = "") => {
   try {
      const response = await fetch(`${config._BaseUrlWithoutAdmin}/plans/${planID}`, {
         method: "DELETE",
         headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${JSON.parse(localStorage.getItem("auth"))}`,
         },
      });

      if (response.status > 204) {
         throw new Error("DELETE plan error");
      }

      return await response.json();
   } catch (error) {
      throw error;
   }
};

export { getPlansService, getPlanByIDService, createNewPlanItemService, deletePlanItemService, updatePlanItemService };
