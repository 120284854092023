import React from "react";

const renderField = ({ input, label, type, meta: { touched, error }, containerClassName, min = false }) => {
   return (
      <div className={containerClassName}>
         <label>{label}</label>
         <input {...input} type={type} className="form-control form-control-lg text-dark" min={min ? min : ""} />
         {touched && error && <div className="alert alert-danger mb-0 mt-1">{error}</div>}
      </div>
   );
};

export default renderField;
