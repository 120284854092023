import { connect } from "react-redux";

import SubscriptionsTable from "./SubscriptionsTable";

import { deleteSubscription } from "../../actions/subscriptions";

const mapDispatchToProps = {
    deleteSubscription
};

export default connect(null, mapDispatchToProps)(SubscriptionsTable);
