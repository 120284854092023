const initState = {
    therapistsList: null,
    currentEditingTherapist: null
};

const therapistsListLoaded = (state, action) => {

    return {
        ...state,
        therapistsList: action.payload
    };
};

const getCurrentEditingTherapist = (state, action) => {

    return {
        ...state,
        currentEditingTherapist: action.payload
    };
};

const clearCurrentEditingTherapist = (state, action) => {

    return {
        ...state,
        currentEditingTherapist: action.payload
    };
};

const deleteTherapist = (state, action) => {

    return {
        ...state,
        therapistsList: state.therapistsList.filter((item)=> item._id !== action.payload)
    };
};


const therapists = (state = initState, action) => {
    switch (action.type) {
        case "THERAPISTS_WAS_LOADED":
            return therapistsListLoaded(state, action);
        case "SET_CURRENT_EDITING_THERAPIST":
            return getCurrentEditingTherapist(state, action);
        case "CLEAR_CURRENT_THERAPIST":
            return clearCurrentEditingTherapist(state, action);
        case "DELETE_THERAPIST":
            return deleteTherapist(state, action);
        default:
            return state;
    }
};

export default therapists;