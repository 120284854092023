import React, { useEffect } from "react";
import { connect } from "react-redux";

import PatientNotesTable from "../../components/PatientNotesTable";

import { getPatientNotesService } from "../../services/patients/patients";
import { patientNotesLoaded } from "../../actions/patients";

import Loader from "../../components/Loader";

const PatientNotes = ({ match: { params }, patientNotesLoaded, patientNotes }) => {
  useEffect(() => {
    getPatientNotesService(params.patientId)
      .then((data) => patientNotesLoaded(data))
      .catch((err) => console.log(err));
  }, []);

  return (
    <div className="w-100 content-container">
      {patientNotes ? (
        <>
          <div className="d-flex justify-content-between align-items-center">
            <h1 className="mb-5">Patient №{params.patientId} - Notes</h1>
          </div>
          {patientNotes.length > 0 ? (
            <PatientNotesTable patientNotes={patientNotes} />
          ) : (
            <div className="alert alert-danger text-center">
              <strong>Patient doesn`t have any notes!</strong>
            </div>
          )}
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
};

const mapStateToProps = ({ patients: { patientNotes } }) => ({
  patientNotes,
});

const mapDispatchToProps = {
  patientNotesLoaded,
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientNotes);
