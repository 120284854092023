import React, { useEffect } from "react";
import { connect } from "react-redux";

import OrganizationsTable from "../../components/OrganizationsTable";
import Loader from "../../components/Loader";

import { getOrganizationsService } from "../../services/organizations/organizations";
import { getOrganizationsList, deleteOrganization } from "../../actions/organizations";

const Organizations = ({ history, getOrganizationsList, organizationsList, deleteOrganization }) => {
  useEffect(() => {
    getOrganizationsService()
      .then((data) => getOrganizationsList(data))
      .catch(() => history.push("/login"));
  }, []);

  return (
    <div className="w-100 content-container">
      {organizationsList ? (
        <>
          <div className="d-flex align-items-center justify-content-between mb-3">
            <h1>Organizations</h1>
          </div>
          <OrganizationsTable
            history={history}
            organizationsList={organizationsList}
            deleteOrganization={deleteOrganization}
          />
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
};

const mapStateToProps = ({ organizations: { organizationsList } }) => ({
  organizationsList,
});

const mapDispatchToProps = {
  getOrganizationsList,
  deleteOrganization,
};

export default connect(mapStateToProps, mapDispatchToProps)(Organizations);
