import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search, CSVExport } from "react-bootstrap-table2-toolkit";
import dayjs from "dayjs";

import { deleteOrganizationService } from "../../services/organizations/organizations";
import confirmDeleteAction from "../../helpers/confirmDeleteAction";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

const { SearchBar } = Search;
const { ExportCSVButton } = CSVExport;

const OrganizationsTable = ({ history, organizationsList, deleteOrganization }) => {
  const columns = [
    {
      dataField: "title",
      text: "Organization title",
      sort: true,
    },
    {
      dataField: "_id",
      text: "Organization ID",
      sort: true,
    },
    {
      dataField: "users_id",
      text: "Owner ID",
      sort: true,
    },
    {
      dataField: "createdDate",
      text: "Creating Date",
      sort: true,
      formatter: (cell, row) => {
        return <>{dayjs(row.createdDate).format("YYYY-MM-DD")}</>;
      },
    },
    {
      dataField: "action",
      text: "Actions",
      sort: false,
      formatter: (cell, row) => {
        return (
          <div className="d-flex justify-content-around">
            <button
              className="btn btn-warning btn-rounded w-75 mr-2"
              onClick={() => history.push(`/organizations/${row._id}`)}
            >
              <i className="far fa-edit mr-2"></i>Edit
            </button>
            <button
              className="btn btn-danger btn-rounded w-25"
              onClick={() => {
                if (confirmDeleteAction()) {
                  deleteOrganizationService(row._id)
                    .then(() => deleteOrganization(row._id))
                    .catch(() => console.log("deleteOrganizationService error"));
                }
              }}
            >
              <i className="far fa-trash-alt"></i>Delete
            </button>
          </div>
        );
      },
    },
  ];

  const defaultSorted = [
    {
      dataField: "title",
      order: "desc",
    },
  ];

  return (
    <ToolkitProvider keyField="_id" bootstrap4 data={organizationsList} columns={columns} search>
      {(props) => (
        <div>
          <div className="d-flex align-items-center">
            <ExportCSVButton {...props.csvProps} className="btn btn-success btn-rounded mb-2 mr-4">
              <i className="fas fa-file-download mr-2"></i>
              Export CSV
            </ExportCSVButton>
            <p className="mb-2 mr-2">Search in table:</p>
            <SearchBar {...props.searchProps} />
          </div>
          <BootstrapTable
            bootstrap4
            defaultSorted={defaultSorted}
            pagination={paginationFactory()}
            noDataIndication="Nothing found. Try to change your request!"
            {...props.baseProps}
            wrapperClasses="table-responsive"
          />
        </div>
      )}
    </ToolkitProvider>
  );
};

export default OrganizationsTable;
