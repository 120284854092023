import dayjs from "dayjs";
import React from "react";
import { Field, reduxForm } from "redux-form";

import renderField from "../FormInputs/Field";

import { updateSubscriptionItemService } from "../../services/subscriptions/subscriptions";

import createSubscriptionPlanObj from "../../adapters/subscription";
import validate from "../../validation/plan-form";

const SubscriptionDetailsForm = ({
  currentEditingSubscription,
  handleSubmit,
  setLoaderVisibilityStatus,
  history,
  subscriptionID,
}) => {
  const submitForm = (values) => {
    setLoaderVisibilityStatus(true);
    const updatedSubscriptionObj = createSubscriptionPlanObj(values);
    updateSubscriptionItemService(subscriptionID, {
      ...updatedSubscriptionObj,
      expirationDate: dayjs(values.expirationDate).format(),
    }).then(() => {
      setLoaderVisibilityStatus(false);
      history.push("/subscriptions");
    });
  };

  return (
    <form id="subscription-form" onSubmit={handleSubmit(submitForm)}>
      <div className="form-row">
        <Field
          name="countOfPatients"
          component={renderField}
          type="number"
          label="Count of patients *"
          placeholder="Enter count of patients"
          containerClassName="form-group col-md-4"
        />
        <Field
          name="countOfTherapists"
          component={renderField}
          type="number"
          label="Count of therapists*"
          placeholder="Enter count of therapists"
          containerClassName="form-group col-md-4"
        />
        <Field
          name="expirationDate"
          component={renderField}
          type="date"
          min={
            currentEditingSubscription
              ? dayjs(currentEditingSubscription.startDate).format("YYYY-MM-DD")
              : dayjs().format("YYYY-MM-DD")
          }
          label="Expiration date *"
          placeholder="Enter count of patients"
          containerClassName="form-group col-md-4"
        />
      </div>
      <div className="form-row">
        <Field
          name="USD"
          component={renderField}
          type="number"
          label="Price in USD *"
          placeholder="Enter price of the plan"
          containerClassName="form-group col-md-3"
        />
        <Field
          name="UAH"
          component={renderField}
          type="number"
          label="Price in UAH *"
          placeholder="Enter price of the plan"
          containerClassName="form-group col-md-3"
        />
        <Field
          name="RUB"
          component={renderField}
          type="number"
          label="Price in RUB*"
          placeholder="Enter price of the plan"
          containerClassName="form-group col-md-3"
        />
        <Field
          name="EUR"
          component={renderField}
          type="number"
          label="Price in EUR*"
          placeholder="Enter price of the plan"
          containerClassName="form-group col-md-3"
        />
      </div>
    </form>
  );
};

export default reduxForm({ form: "SubscriptionDetailsForm", validate })(SubscriptionDetailsForm);
