import React from "react";
import { Field, reduxForm } from "redux-form";

import renderField from "../FormInputs/Field";

import { createNewPlanItemService, updatePlanItemService } from "../../services/plans/plans";

import validate from "../../validation/plan-form";
import createPlanObj from "../../adapters/plan-form";
import PLAN_DURATION from "../../constants/parametrs/plan";

const PlanDetailsForm = ({ currentEditingPlan, handleSubmit, setLoaderVisibilityStatus, history, planID }) => {
  const submitForm = (values) => {
    setLoaderVisibilityStatus(true);
    const planObj = createPlanObj(values);
    if (currentEditingPlan) {
      updatePlanItemService({ ...planObj, _id: planID }).then(() => {
        setLoaderVisibilityStatus(false);
        history.push("/plans");
      });
    } else {
      createNewPlanItemService(planObj).then(() => {
        setLoaderVisibilityStatus(false);
        history.push("/plans");
      });
    }
  };

  return (
    <form id="plan-form" onSubmit={handleSubmit(submitForm)}>
      <div className="form-row">
        <Field
          name="title_en"
          component={renderField}
          type="text"
          label="Title on English*"
          placeholder="Enter title of this plan"
          containerClassName="form-group col-md-4"
        />
        <Field
          name="title_ru"
          component={renderField}
          type="text"
          label="Title on Russian*"
          placeholder="Enter title of this plan"
          containerClassName="form-group col-md-4"
        />
        <Field
          name="title_uk"
          component={renderField}
          type="text"
          label="Title on Ukrainian*"
          placeholder="Enter title of this plan"
          containerClassName="form-group col-md-4"
        />
      </div>
      <div className="form-row">
        <Field
          name="USD"
          component={renderField}
          type="number"
          label="Price in USD *"
          placeholder="Enter price of the plan"
          containerClassName="form-group col-md-3"
        />
        <Field
          name="UAH"
          component={renderField}
          type="number"
          label="Price in UAH *"
          placeholder="Enter price of the plan"
          containerClassName="form-group col-md-3"
        />
        <Field
          name="RUB"
          component={renderField}
          type="number"
          label="Price in RUB*"
          placeholder="Enter price of the plan"
          containerClassName="form-group col-md-3"
        />
        <Field
          name="EUR"
          component={renderField}
          type="number"
          label="Price in EUR*"
          placeholder="Enter price of the plan"
          containerClassName="form-group col-md-3"
        />
      </div>
      <div className="form-row">
        <div className="form-group col-md-4">
          <label>Duration *</label>

          <Field name="duration" component="select" className="form-control form-control-lg text-dark">
            {PLAN_DURATION.map((item, index) => {
              return (
                <option key={index} value={item.duration}>
                  {item.title}
                </option>
              );
            })}
          </Field>
        </div>
        <Field
          name="countOfPatients"
          component={renderField}
          type="number"
          label="Count of patients *"
          placeholder="Enter count of patients in this plan"
          containerClassName="form-group col-md-4"
        />
        <Field
          name="countOfTherapists"
          component={renderField}
          type="number"
          label="Сount of therapists *"
          placeholder="Enter count of therapists in this plan"
          containerClassName="form-group col-md-4"
        />
      </div>
      <div className="form-row">
        <Field
          name="description_en"
          component={renderField}
          type="text"
          label="Description on English*"
          placeholder="Enter title of this plan"
          containerClassName="form-group col-md-4"
        />
        <Field
          name="description_ru"
          component={renderField}
          type="text"
          label="Description on Russian*"
          placeholder="Enter title of this plan"
          containerClassName="form-group col-md-4"
        />
        <Field
          name="description_uk"
          component={renderField}
          type="text"
          label="Description on Ukrainian*"
          placeholder="Enter title of this plan"
          containerClassName="form-group col-md-4"
        />
      </div>
      <div className="form-row">
        <div className="form-group col-md-4">
          <Field name="isVisible" component="input" id="show-input" type="checkbox" className="mr-2" />
          <label className="form-check-label" htmlFor="show-input">
            Show in CLIENT APP?
          </label>
        </div>
      </div>
    </form>
  );
};

export default reduxForm({ form: "PlanDetailsForm", validate })(PlanDetailsForm);
