import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import dayjs from "dayjs";

import { deletePatientNoteItemService } from "../../services/patients/patients";
import confirmDeleteAction from "../../helpers/confirmDeleteAction";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

const { SearchBar } = Search;

const PatientNotesTable = ({ patientNotes, deletePatientNoteItem }) => {
  const columns = [
    {
      dataField: "date",
      text: "Created date",
      sort: false,
      formatter: (cell, row) => {
        return <>{dayjs(row.date.create).format("YYYY-MM-DD")}</>;
      },
    },
    {
      dataField: "organization_id",
      text: "Organization ID",
      sort: true,
    },
    {
      dataField: "",
      text: "Creator name",
      sort: false,
      formatter: (cell, row) => {
        return <>{`${row.creator_id.name.first} ${row.creator_id.name.last}`}</>;
      },
    },
    {
      dataField: "note",
      text: "Note",
      sort: true,
      formatter: (cell, row) => {
        return <>{row.note ? row.note : "-"}</>;
      },
    },
    {
      dataField: "action",
      text: "Actions",
      sort: false,
      formatter: (cell, row) => {
        return (
          <div className="d-flex justify-content-around">
            <button
              className="btn btn-danger btn-rounded w-100"
              onClick={() => {
                if (confirmDeleteAction()) {
                  deletePatientNoteItemService(row._id)
                    .then(() => deletePatientNoteItem(row._id))
                    .catch(() => console.log("deletePatientNoteItemService error"));
                }
              }}
            >
              <i className="far fa-trash-alt mr-3"></i>Delete
            </button>
          </div>
        );
      },
    },
  ];

  const defaultSorted = [
    {
      dataField: "organization_id",
      order: "desc",
    },
  ];

  return (
    <ToolkitProvider keyField="_id" bootstrap4 data={patientNotes} columns={columns} search>
      {(props) => (
        <div>
          <div className="d-flex align-items-center">
            <p className="mb-2 mr-2">Search in table:</p>
            <SearchBar {...props.searchProps} />
          </div>
          <BootstrapTable
            bootstrap4
            defaultSorted={defaultSorted}
            pagination={paginationFactory()}
            noDataIndication="Nothing found. Try to change your request!"
            {...props.baseProps}
            wrapperClasses="table-responsive"
          />
        </div>
      )}
    </ToolkitProvider>
  );
};

export default PatientNotesTable;
