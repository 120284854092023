import config from '../config';

const getLibrariesService = async () => {

    try {
        const response = await fetch(`${config._BaseUrlWithoutAdmin}/library/all`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem('auth'))}`
            }
        });

        if (response.status > 204) {
            throw new Error('GET library error')
        }

        return await response.json();
    } catch (error) {
        throw new Error('GET library error')
    }
}

const addLibraryService = async (libraryData) => {

    try {
        const response = await fetch(`${config._BaseUrlWithoutAdmin}/library`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem('auth'))}`
            },
            body: JSON.stringify(libraryData)
        });

        if (response.status > 204) {
            throw new Error('POST library error')
        }

        return await response.json();
    } catch (error) {
        throw new Error('POST library error')
    }
}

const addLibraryAttachmentsService = async ( libraryId, libraryAttachmentsList) => {
    
    const attachmentsList = new FormData();
    attachmentsList.append('id', libraryId);
    
    libraryAttachmentsList.forEach((item)=> {
        if(item.file) {
            attachmentsList.append('attachments', item.file);
        }
    })

    try {
        const response = await fetch(`${config._BaseUrlWithoutAdmin}/library/attachment`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem('auth'))}`
            },
            body: attachmentsList
        });

        if (response.status > 204) {
            throw new Error('POST library attachments error')
        }

        return await response.json();
    } catch (error) {
        throw new Error('POST library attachments error')
    }
}

const deleteLibraryByIdService = async (libraryId) => {

    try {
        const response = await fetch(`${config._BaseUrlWithoutAdmin}/library/${libraryId}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem('auth'))}`
            },
        });

        if (response.status > 204) {
            throw new Error('Delete library error')
        }

        return await response.json();
    } catch (error) {
        throw new Error('Delete library error')
    }
}

const getLibraryByIdService = async (libraryId) => {

    try {
        const response = await fetch(`${config._BaseUrlWithoutAdmin}/library/${libraryId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem('auth'))}`
            }
        });

        if (response.status > 204) {
            throw new Error('GET library by id error')
        }

        return await response.json();
    } catch (error) {
        throw new Error('GET library by id error')
    }
}

const updateLibraryByIdService = async (libraryObj) => {

    try {
        const response = await fetch(`${config._BaseUrlWithoutAdmin}/library`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem('auth'))}`
            },
            body: JSON.stringify(libraryObj)
        });

        if (response.status > 204) {
            throw new Error('PUT library by id error')
        }

        return await response.json();
    } catch (error) {
        throw new Error('PUT library by id error')
    }
}

export {
    getLibrariesService,
    addLibraryService,
    addLibraryAttachmentsService,
    deleteLibraryByIdService,
    getLibraryByIdService,
    updateLibraryByIdService
}