import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

import {
    deleteTherapistThumbnailService,
    setThumbnailService
} from '../../services/thumbnail/thumbnail';
import { updateTherapistByIdService } from '../../services/therapists/therapists';

import config from '../../services/config';

const baseUrl = config._Domain;

const TherapistDetailsForm = ({ handleSubmit,
    currentEditingTherapist,
    therapistId }) => {

    const [image, setNewImage] = useState(currentEditingTherapist.thumbnail || null);

    const fileChangedHandler = (event) => {
        const file = event.target.files[0];
        const img = URL.createObjectURL(file);
        setThumbnailService(file, 'personalImage', therapistId)
            .then(() => setNewImage(img))
            .catch(() => console.log("Organization thumbnail error"))

    }

    const submitForm = (values) => {

        values.birthday = new Date(values.birthday_date)

        updateTherapistByIdService(therapistId, values)
    }

    return (
        <form id="therapist-details-form" onSubmit={handleSubmit(submitForm)}>
            <div className="form-row">
                <div className="form-group col-md-12">
                    <label>Patient thumbnail</label>
                    <div className="image-container">
                        <div className="image-preview-container">
                            {
                                image && (
                                    <img src={image.substring(0, 4) === 'blob' ? image : `${baseUrl}${currentEditingTherapist.thumbnail}`} className="image-preview" />
                                )
                                ||
                                (
                                    <i className="empty-image-icon" />
                                )
                            }
                        </div>
                        <label className="btn btn-primary mb-0 mr-4" htmlFor='therapist-image'>
                            Upload image
                        </label>
                        <input type="file" accept="image/*" id="therapist-image" onChange={(e) => fileChangedHandler(e)}></input>
                        {
                            image && (
                                <div type="file" className="btn btn-danger" onClick={() => {
                                    deleteTherapistThumbnailService(therapistId)
                                        .then(() => setNewImage(null))
                                        .catch(() => console.log('delete thumbnail error'))

                                    document.getElementById('therapist-image').value = null;
                                }}>Delete image</div>
                            ) || null
                        }
                    </div>
                </div>
            </div>
            <div className="form-row">
                <div className="form-group col-md-4">
                    <label>Email</label>
                    <Field
                        name="email"
                        component="input"
                        type="text"
                        className="form-control form-control-lg text-dark"
                        disabled
                    />
                </div>
                <div className="form-group col-md-4">
                    <label>Gender</label>
                    <Field
                        name="gender"
                        component="select"
                        className="form-control form-control-lg text-dark"
                    >
                        <option value='male'>Male</option>
                        <option value='female'>Female</option>
                        <option value='other'>Other</option>
                    </Field>
                </div>
                <div className="form-group col-md-4">
                    <label>Birthday</label>
                    <Field
                        name="birthday_date"
                        component="input"
                        type="date"
                        className="form-control form-control-lg text-dark"
                        min="1955-01-01"
                        max="2005-12-31"
                    />
                </div>
            </div>
            <div className="form-row">
                <div className="form-group col-md-6">
                    <label>Job title</label>
                    <Field
                        name="job_title"
                        component="input"
                        type="text"
                        className="form-control form-control-lg text-dark"
                    />
                </div>
                <div className="form-group col-md-6">
                    <label>Biography</label>
                    <Field
                        name="bio"
                        component="textarea"
                        className="form-control form-control-lg text-dark"
                    />
                </div>
            </div>
            <div className="form-row">
                <div className="form-group col-md-6">
                    <label>Phone:</label>
                    <Field
                        name="phone"
                        component="input"
                        type="text"
                        className="form-control form-control-lg text-dark"
                    />
                </div>
            </div>
        </form>
    )
}


const mapStateToProps = ({ therapists: { currentEditingTherapist } }) => ({
    currentEditingTherapist,
    initialValues: {
        email: currentEditingTherapist.email,
        gender: currentEditingTherapist.gender,
        job_title: currentEditingTherapist.job_title,
        phone: currentEditingTherapist.phone,
        bio: currentEditingTherapist.bio,
        birthday_date: `${new Date(currentEditingTherapist.birthday).getFullYear()}-${new Date(currentEditingTherapist.birthday).getMonth() <= 9 ? (`${new Date(currentEditingTherapist.birthday).getMonth()}` != 0 ? `0${new Date(currentEditingTherapist.birthday).getMonth()}` : `${new Date(currentEditingTherapist.birthday).getMonth()}` + 1) : new Date(currentEditingTherapist.birthday).getMonth()}-${new Date(currentEditingTherapist.birthday).getDate() <= 9 ? `0${new Date(currentEditingTherapist.birthday).getDate()}` : new Date(currentEditingTherapist.birthday).getDate()}`,

    }
})


const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(reduxForm({ form: 'TherapistDetailsForm' })(TherapistDetailsForm));

