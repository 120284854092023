import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

import { updateOrganizationByIdService } from '../../services/organizations/organizations';
import { 
    setThumbnailService, 
    deleteOrganizationThumbnailService } from '../../services/thumbnail/thumbnail';
import config from '../../services/config';

import './styles.css';

const baseUrl = config._Domain;

const OrganizationDetailsForm = ({ organizationId, 
    currenciesList, 
    unitSystemList, 
    countriesList, 
    languagesList, 
    currentEditingOrganization, 
    updateCurrentOrganization, 
    handleSubmit }) => {

    const [image, setNewImage] = useState(currentEditingOrganization.thumbnail || null);

    const fileChangedHandler = (event) => {
        const file = event.target.files[0];
        const img = URL.createObjectURL(file);
        setThumbnailService(file, 'organizationImage', organizationId)
            .then( () =>  setNewImage(img))
            .catch( () => console.log("Organization thumbnail error"))

    }

    const submitForm = (values) => {

        values.phone = [values.firstPhone, values.secondPhone];

        updateOrganizationByIdService(organizationId, values)
            .then((data) => updateCurrentOrganization(data))
    }

    return (
        <form id="organization-details-form" onSubmit={handleSubmit(submitForm)}>
            <div className="form-row">
                <div className="form-group col-md-12">
                    <label>Organization image</label>
                    <div className="image-container">
                        <div className="image-preview-container">
                            {
                                image && (
                                    <img src={image.substring(0, 4) === 'blob' ? image : `${currentEditingOrganization.thumbnail}`} className="image-preview" />
                                )
                                ||
                                (
                                    <i className="empty-image-icon" />
                                )
                            }
                        </div>
                        <label className="btn btn-primary mb-0 mr-4" htmlFor='organization-image'>
                            Upload image
                        </label>
                        <input type="file" accept="image/*" id="organization-image" onChange={(e) => fileChangedHandler(e)}></input>
                        {
                            image && (
                                <div type="file" className="btn btn-danger" onClick={() => {
                                    deleteOrganizationThumbnailService(organizationId);
                                    setNewImage(null);
                                    document.getElementById('organization-image').value = null;
                                }}>Delete image</div>
                            ) || null
                        }
                    </div>
                </div>
            </div>
            <div className="form-row">
                <div className="form-group col-md-4">
                    <label>Organization title</label>
                    <Field
                        name="title"
                        component="input"
                        type="text"
                        placeholder="Enter organization title"
                        className="form-control form-control-lg text-dark"
                    />
                </div>
                <div className="form-group col-md-4">
                    <label>Organization website</label>
                    <Field
                        name="website"
                        component="input"
                        type="text"
                        placeholder="Enter organization website"
                        className="form-control form-control-lg text-dark"
                    />
                </div>
                <div className="form-group col-md-4">
                    <label>Organization address</label>
                    <Field
                        name="address"
                        component="input"
                        type="text"
                        placeholder="Enter organization email"
                        className="form-control form-control-lg text-dark"
                    />
                </div>
            </div>
            <div className="form-row">
                <div className="form-group col-md-4">
                    <label>Organization zip</label>
                    <Field
                        name="zip"
                        component="input"
                        type="number"
                        placeholder="Enter organization zip code"
                        className="form-control form-control-lg text-dark"
                    />
                </div>
                <div className="form-group col-md-4">
                    <label>Organization first phone</label>
                    <Field
                        name="firstPhone"
                        component="input"
                        type="number"
                        placeholder="Enter organization first phone"
                        className="form-control form-control-lg text-dark"
                    />
                </div>
                <div className="form-group col-md-4">
                    <label>Organization second phone</label>
                    <Field
                        name="secondPhone"
                        component="input"
                        type="number"
                        placeholder="Enter organization second phone"
                        className="form-control form-control-lg text-dark"
                    />
                </div>
            </div>
            <div className="form-row">
                <div className="form-group col-md-4">
                    <label>Organization language</label>
                    <Field
                        name="languages_id"
                        component="select"
                        placeholder="Enter organization zip code"
                        className="form-control form-control-lg text-dark"
                    >
                        {
                            languagesList ?
                                languagesList.map((item) => {
                                    return (
                                        <option key={item._id} value={item._id}>{item.name}</option>
                                    )
                                })
                                : null
                        }
                    </Field>
                </div>
                <div className="form-group col-md-4">
                    <label>Organization metric system</label>
                    <Field
                        name="unit_systems_id"
                        component="select"
                        placeholder="Enter organization first phone"
                        className="form-control form-control-lg text-dark"
                    >
                        {
                            unitSystemList ?
                                unitSystemList.map((item) => {
                                    return (
                                        <option key={item._id} value={item._id}>{item.name.en}</option>
                                    )
                                })
                                : null
                        }
                    </Field>
                </div>
                <div className="form-group col-md-4">
                    <label>Organization currency</label>
                    <Field
                        name="currencies_id"
                        component="select"
                        placeholder="Enter organization second phone"
                        className="form-control form-control-lg text-dark"
                    >
                        {
                            currenciesList ?
                                currenciesList.map((item) => {
                                    return (
                                        <option key={item._id} value={item._id}>{item.name.en} | {item.symbol}</option>
                                    )
                                })
                                : null
                        }
                    </Field>
                </div>
            </div>
            <div className="form-row">
                <div className="form-group col-md-4">
                    <label>Organization description</label>
                    <Field
                        name="description"
                        component="textarea"
                        placeholder="Enter organization description"
                        className="form-control form-control-lg text-dark"
                    />
                </div>
                <div className="form-group col-md-4">
                    <label>Organization country</label>
                    <Field
                        name="countries_id"
                        component="select"
                        placeholder="Enter organization country"
                        className="form-control form-control-lg text-dark"
                    >
                        <option value={null}>Not indicated</option>
                        {
                            countriesList ?
                                countriesList.map((item) => {
                                    return (
                                        <option key={item._id} value={item._id}>{item.name.en}</option>
                                    )
                                })
                                : null
                        }
                    </Field>
                </div>
                <div className="form-group col-md-4">
                    <label>Organization city</label>
                    <Field
                        name="city"
                        component="input"
                        placeholder="Enter organization city"
                        className="form-control form-control-lg text-dark"
                    >
                    </Field>
                </div>
            </div>
        </form>
    )
}

const mapStateToProps = ({ organizations: { currentEditingOrganization }, lists: { currenciesList, unitSystemList, languagesList, countriesList } }) => ({
    currentEditingOrganization,
    currenciesList,
    unitSystemList,
    languagesList,
    countriesList,
    initialValues: {
        title: currentEditingOrganization.title,
        website: currentEditingOrganization.website,
        address: currentEditingOrganization.address,
        zip: currentEditingOrganization.zip,
        firstPhone: parseInt(currentEditingOrganization.phone[0]) || '',
        secondPhone: parseInt(currentEditingOrganization.phone[1]) || '',
        currencies_id: currentEditingOrganization.currencies_id,
        unit_systems_id: currentEditingOrganization.unit_systems_id,
        languages_id: currentEditingOrganization.languages_id,
        description: currentEditingOrganization.description,
        countries_id: currentEditingOrganization.countries_id || null,
        city: currentEditingOrganization.city || ''
    }
});

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(reduxForm({ form: 'OrganizationDetailsForm' })(OrganizationDetailsForm));
