const getPatientsList = (data) => {
  return {
    type: "PATIENTS_WAS_LOADED",
    payload: data,
  };
};

const deletePatient = (patientId) => {
  return {
    type: "DELETE_PATIENT",
    payload: patientId,
  };
};

const setCurrentEditingPatient = (data) => {
  return {
    type: "SET_CURRENT_EDITING_PATIENT",
    payload: data,
  };
};

const clearCurrentPatient = () => {
  return {
    type: "CLEAR_CURRENT_PATIENT",
  };
};

const patientDiseasesLoaded = (data) => {
  return {
    type: "PATIENT_DISEASES_LOADED",
    payload: data,
  };
};

const patientNotesLoaded = (data) => {
  return {
    type: "PATIENT_NOTES_LOADED",
    payload: data,
  };
};

const patientAttachmentsLoaded = (data) => {
  return {
    type: "PATIENT_ATTACHMENTS_LOADED",
    payload: data,
  };
};

const patientAssessmentFormsLoaded = (data) => {
  return {
    type: "PATIENT_ASSESSMENT_FORMS_LOADED",
    payload: data,
  };
};

const patientAppointmentsLoaded = (data) => {
  return {
    type: "PATIENT_APPOINTMENTS_LOADED",
    payload: data,
  };
};

const deletePatientDiseaseItem = (diseaseID) => {
  return {
    type: "DELETE_PATIENT_DISEASE_ITEM",
    payload: diseaseID,
  };
};

const deletePatientNoteItem = (diseaseID) => {
  return {
    type: "DELETE_PATIENT_NOTE_ITEM",
    payload: diseaseID,
  };
};

const deletePatientAttachmentItem = (attachmentID) => {
  return {
    type: "DELETE_PATIENT_ATTACHMENT_ITEM",
    payload: attachmentID,
  };
};

const deletePatientAppointmentItem = (appointmentID) => {
  return {
    type: "DELETE_PATIENT_APPOINTMENT_ITEM",
    payload: appointmentID,
  };
};

const deletePatientAssessmentFormItem = (assessmentFormID) => {
  return {
    type: "DELETE_PATIENT_ASSESSMENT_FORM_ITEM",
    payload: assessmentFormID,
  };
};

const patientHomeworksListLoaded = (homeworkList) => {
  return {
    type: "PATIENT_HOMEWORK_LIST_LOADED",
    payload: homeworkList,
  };
};

const deletePatientHomeworksItem = (homeworkID) => {
  return {
    type: "DELETE_PATIENT_HOMEWORK_ITEM",
    payload: homeworkID,
  };
};

export {
  getPatientsList,
  deletePatient,
  setCurrentEditingPatient,
  clearCurrentPatient,
  patientDiseasesLoaded,
  patientNotesLoaded,
  patientAttachmentsLoaded,
  patientAssessmentFormsLoaded,
  patientAppointmentsLoaded,
  deletePatientDiseaseItem,
  deletePatientNoteItem,
  deletePatientAttachmentItem,
  deletePatientAppointmentItem,
  deletePatientAssessmentFormItem,
  patientHomeworksListLoaded,
  deletePatientHomeworksItem,
};
