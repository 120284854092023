import Plans from "./Plans";
import { connect } from "react-redux";

import { getPlansList } from "../../actions/plans";

const mapStateToProps = ({ plans: { plansList } }) => ({
   plansList,
});

const mapDispatchToProps = {
    getPlansList
};

export default connect(mapStateToProps, mapDispatchToProps)(Plans);
