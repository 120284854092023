const initState = {
    isVisibleLoader: false
};

const setLoaderStatus = (state, action) => {

    return {
        ...state,
        isVisibleLoader: action.payload
    };
};

const loader = (state = initState, action) => {
    switch (action.type) {
        case "SET_LOADER_STATUS":
            return setLoaderStatus(state, action);
        default:
            return state;
    }
};

export default loader;