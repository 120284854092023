const getSubscriptionsList = (data) => {
   return {
      type: "SUBSCRIPTIONS_WAS_LOADED",
      payload: data,
   };
};

const deleteSubscription = (planID) => {
   return {
      type: "DELETE_SUBSCRIPTION",
      payload: planID,
   };
};

const setCurrentEditingSubscription = (data) => {
   return {
      type: "SET_CURRENT_EDITING_SUBSCRIPTION",
      payload: data,
   };
};

export { getSubscriptionsList, deleteSubscription, setCurrentEditingSubscription };
