import React, { useEffect } from "react";
import { connect } from "react-redux";

import PatientHomeworksTable from "../../components/PatientHomeworksTable";

import { getPatientHomeworksService } from "../../services/patients/patients";
import { patientHomeworksListLoaded } from "../../actions/patients";

import Loader from "../../components/Loader";

const PatientHomeworks = ({ match: { params }, patientHomeworksListLoaded, patientHomeworksList }) => {
  useEffect(() => {
    getPatientHomeworksService(params.patientId)
      .then((data) => patientHomeworksListLoaded(data))
      .catch((err) => console.log(err));
  }, []);

  return (
    <div className="w-100 content-container">
      {patientHomeworksList ? (
        <>
          <div className="d-flex justify-content-between align-items-center">
            <h1 className="mb-5">Patient №{params.patientId} - Homeworks</h1>
          </div>
          {patientHomeworksList.length > 0 ? (
            <PatientHomeworksTable patientHomeworksList={patientHomeworksList} />
          ) : (
            <div className="alert alert-danger text-center">
              <strong>Patient doesn`t have any homeworks!</strong>
            </div>
          )}
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
};

const mapStateToProps = ({ patients: { patientHomeworksList } }) => ({
  patientHomeworksList,
});

const mapDispatchToProps = {
  patientHomeworksListLoaded,
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientHomeworks);
