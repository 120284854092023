import React from "react";
import { connect } from "react-redux";

import PlanDetailsForm from "../../components/PlanDetailsForm";

import { setCurrentEditingPlan } from "../../actions/plans";

const NewPlan = ({ history, setCurrentEditingPlan, isVisibleLoader }) => {
   setCurrentEditingPlan(null);

   return (
      <div className="w-100 content-container">
         <div className="d-flex align-items-center justify-content-between mb-5">
            <h1>New plan</h1>
            <button className="ml-5 btn btn-primary" form="plan-form">
               {isVisibleLoader ? (
                  <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
               ) : (
                  <i className="fas fa-save mr-2"></i>
               )}
               Create new plan item
            </button>
         </div>
         <PlanDetailsForm history={history} />
      </div>
   );
};

const mapStateToProps = ({ loader: { isVisibleLoader } }) => ({
   isVisibleLoader,
});

const mapDispatchToProps = {
   setCurrentEditingPlan,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewPlan);
