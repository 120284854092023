import config from "../config";
import { saveTokenInLocalStorage } from "../token-handler/token-handler";

const checkLoginCredential = async (data) => {
   try {
      const response = await fetch(`${config._BaseUrl}/auth/signin`, {
         method: "POST",
         headers: {
            "Content-Type": "application/json",
         },
         body: JSON.stringify(data),
      });

      return await response.json();
   } catch (error) {
      console.log(error);
   }
};

const loginUserService = async (values, history, setAvailableToken) => {
   let res = await checkLoginCredential(values);

   if (!res.auth) {
      throw new Error("login error");
   } else {
      saveTokenInLocalStorage(res.auth);
      setAvailableToken(true);
      localStorage.setItem("currentLoginedUserEmail", values.email);
      history.push("/organizations");
   }
};

export default loginUserService;
