import { connect } from "react-redux";

import PatientHomeworksTable from "./PatientHomeworksTable";

import { deletePatientHomeworksItem } from '../../actions/patients';

const mapDispatchToProps = {
    deletePatientHomeworksItem
};

export default connect(null, mapDispatchToProps)(PatientHomeworksTable);
